import Space from "../Modules/Nav/SpaceBehindNav";
import SearchBox from "../Modules/SearchBox/SearchBox";
import Config from '../Modules/Config/Config';
import { useContext } from "react";
import { AppContext } from "../context/AppContext";


const Settings = () => {


  const Context = useContext(AppContext);
  if (!Context) {
    // Manejar el caso cuando el contexto es nulo
    return null;
  }
  const { searchVisible } = Context;

  return (
    <>
      {!searchVisible && (
        <>
          <Config />
        </>
      )}
    </>
  )
}

export default Settings