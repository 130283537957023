export const MessageType = Object.freeze({
  TEXT: "TEXT",
  AUDIO: "AUDIO",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
});

export const MediaType = Object.freeze({
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
});
