import React, { useEffect, useState } from "react";
import { MessagesModel } from "../../../../../Models/Message/MessageDto";
import { MessageParams } from "../../../../../Models/Message/MessageParams";
import { UserPerfilModel } from "../../../../../Models/User/UserPerfilModel";
import UserProfile from "../../../../../assets/img/UserProfile.jpg";
import Auth from "../../../../../Services/Auth/AuthService";
import Chat_S from "../../../../../Services/Chat/ChatService";
import ChargerSlice from "../../../../ChargerSlice/ChargerSlice";
import "./MessageContainer.css";

interface IMessageContainer {
  userData: UserPerfilModel | undefined;
  messages?: any;
  idChat: string | undefined;
  setMessages: Function;
}
const MessageContainer = (props: IMessageContainer) => {
  const messageRef = React.createRef<HTMLInputElement>();
  const [messagesOnDB, setMessagesOnDB] = useState<MessagesModel[]>([]);

  const todayDate = new Date();
  const todayStr = todayDate.toString().substr(0, 15);
  const AyerDate = new Date();
  AyerDate.setDate(AyerDate.getDate() - 1);
  const Ayer = AyerDate;
  var DateDBOrigin = "";

  useEffect(() => {
    if (messageRef && messageRef.current) {
      const { scrollHeight, clientHeight } = messageRef.current;
      messageRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }
  }, [props.messages, messageRef]);

  useEffect(() => {
    if (props.idChat) {
      GetMessages(props.idChat);
    }
  }, [props.idChat]);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_TITTLE_BASE} • Chat (${props.userData?.user?.username})`;
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, [props.userData]);

  const GetMessages = (id: string | undefined) => {
    //Hacer la paginacion
    Chat_S.GetAllMessages(id!, 1)
      .then((res: any) => {
        setMessagesOnDB(res.data);
        props.setMessages([]);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <div className="chat-box" ref={messageRef}>
      {!props.idChat && <ChargerSlice />}
      {props.idChat && (
        <>
          <div>
            <div className="peerfiluserchat">
              {props.userData?.profilePhoto ? (
                <img src={props.userData?.profilePhoto} alt="" />
              ) : (
                <img src={UserProfile} alt="" />
              )}
            </div>
            <div>
              <b style={{ cursor: "pointer" }}>{props.userData?.user?.name}</b>
              <br />
              <span>@{props.userData?.user?.username}</span>
              <br />
              <span>{props.userData?.seguidores} Followers • 23 Post</span>
            </div>
          </div>
          <hr />
          {messagesOnDB.map((m: MessagesModel, index: number) => {
            if (m.idChat === props.idChat) {
              const day = parseInt(`${m.createDate_Js?.substr(8, 2)}`);
              const month = parseInt(`${m.createDate_Js?.substr(5, 2)}`) - 1;
              const year = parseInt(`${m.createDate_Js?.substr(0, 4)}`);
              const fechaDate = new Date(year, month, day);
              const fecha = fechaDate.toString().substr(0, 15);

              return (
                <div key={index}>
                  {DateDBOrigin !== fecha.substr(0, 15) && (
                    <>
                      <div className="disNone">
                        {(DateDBOrigin = fecha.substr(0, 15).toString())}
                      </div>
                      <>
                        {todayStr === fecha.substr(0, 15) && (
                          <div className="dateContain hoy">
                            <span>Hoy</span>
                          </div>
                        )}
                        {Ayer.toString().substr(0, 15) ===
                          fecha.substr(0, 15) && (
                          <div className="dateContain hoy">
                            <span>Ayer</span>
                          </div>
                        )}
                        {todayStr !== fecha.substr(0, 15) &&
                          Ayer.toString().substr(0, 15) !==
                            fecha.substr(0, 15) && (
                            <>
                              {parseInt(todayStr.substr(11, 4)) === year ? (
                                <div className="dateContain">
                                  <span>{fecha.substr(0, 11)}</span>
                                </div>
                              ) : (
                                <div className="dateContain year">
                                  <span>{fecha.substr(0, 15)}</span>
                                </div>
                              )}
                            </>
                          )}
                      </>
                    </>
                  )}

                  {m.idUser === Auth._User?.user?.id! && (
                    <div className="chat outgoing">
                      <div className="details">
                        <p>
                          {m.messageValue}
                          <br />{" "}
                          <span className="time_date outtime">
                            {parseInt(`${m.createDate_Js?.substr(11, 2)}`) !==
                              undefined &&
                            parseInt(`${m.createDate_Js?.substr(11, 2)}`) > 12
                              ? parseInt(`${m.createDate_Js?.substr(11, 2)}`) -
                                12 +
                                `:${m.createDate_Js?.substr(14, 2)}pm`
                              : `${m.createDate_Js?.substr(11, 2)}` === "00"
                              ? `12:${m.createDate_Js?.substr(14, 2)}am`
                              : `${m.createDate_Js?.substr(11, 5)}am`}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}

                  {m.idUser === props.userData?.user?.id && (
                    <div className="chat incoming">
                      {props.userData?.profilePhoto ? (
                        <img src={props.userData?.profilePhoto} alt="" />
                      ) : (
                        <img src={UserProfile} alt="" />
                      )}
                      <div className="details">
                        <p>
                          {m.messageValue}
                          <br />{" "}
                          <span className="time_date intime">7:40 pm</span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          })}
          {props.messages.map((m: MessageParams, index: number) => {
            if (m.id === props.idChat) {
              return (
                <div key={index}>
                  {DateDBOrigin !== todayStr && (
                    <div className="dateContain hoy">
                      <span>Hoy</span>
                    </div>
                  )}

                  {m.idUserSender === Auth._User?.user?.id! && (
                    <div className="chat outgoing">
                      <div className="details">
                        <p>
                          {m.messageValue}
                          <br />{" "}
                          <span className="time_date outtime">7:40 pm</span>
                        </p>
                      </div>
                    </div>
                  )}

                  {m.idUserSender === props.userData?.user?.id && (
                    <div className="chat incoming">
                      {props.userData?.profilePhoto ? (
                        <img src={props.userData?.profilePhoto} alt="" />
                      ) : (
                        <img src={UserProfile} alt="" />
                      )}
                      <div className="details">
                        <p>
                          {m.messageValue}
                          <br />{" "}
                          <span className="time_date intime">7:40 pm</span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          })}
        </>
      )}
    </div>
  );
};

export default MessageContainer;
