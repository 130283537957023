import React, {  useContext, useEffect, useState } from "react";
import "./Coments.css";
import UserProfile from "../../../assets/img/UserProfile.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faHeart } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ComentModel } from "../../../Models/Coment/ComentModel";
import Like from "../../../Services/Like/LikeService";
import Auth from "../../../Services/Auth/AuthService";
import EditModal from "../../Modals/EditModal";
import { ComentParams } from "../../../Models/Coment/ComentParams";
import Data from "../../../Services/Data/Data";
import Coment from "../../../Services/Coments/ComentService";
import { ImageModel } from "../../../Models/Post/ImageModel";
import { AppContext } from "../../../context/AppContext";
import MediaData from "../../../Services/MediaData/MediaDataService";
import ModalOptions from "../../Modals/ModalOptions";
import { ModalOptionModel } from "../../../Models/data/ModalOptionModel";

interface IComents {
  coment: ComentModel;
  handleComentChange: Function;
  onDelete: Function;
}

const Coments = ({ coment, handleComentChange, onDelete }: IComents) => {
  const navigate = useNavigate();
  const [likes, setLikes] = useState<number>(0);
  const [comentPerfilData, setComentPerfilData] = useState<ImageModel>();
  const [liked, setLiked] = useState<boolean>(false);
  const [isModalOptionsVisible, setModalOptionsVisible] =
    useState<boolean>(false);
  const [isModalEditVisible, setModalEditVisible] = useState<boolean>(false);

  useEffect(() => {
    setLiked(coment.liked!);
    setLikes(coment.likes!);
  }, [coment]);

  useEffect(() => {
    if (coment.idMediaDataProfile!) {
      handleSrc(coment.idMediaDataProfile!);
    }
  }, [coment.idMediaDataProfile!]);

  const Context = useContext(AppContext);
  if (!Context) {
    return null;
  }
  const { searchImage, handleImageBank } = Context;

  const handleSrc = (id: string) => {
    const image = searchImage(id);
    if (image.length > 0) {
      setComentPerfilData(image[0]);
    } else {
      MediaData.GetById(id)
        .then((e: any) => {
          setComentPerfilData(e.data);
          handleImageBank(e.data);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  const ActionLike = (idUser: string, idComent: string) => {
    liked
      ? Like.RemoveLikeComent(idUser, idComent)
          .then((e: any) => {})
          .catch((err: any) => {
            console.log(err);
          })
      : Like.setLikeComent(idUser, idComent)
          .then((e: any) => {})
          .catch((err: any) => {
            console.log(err);
          });
  };

  const Delete = (id: string) => {
    Coment.Delete(id)
      .then((e: any) => {
        onDelete();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const options: ModalOptionModel[] = [
    {
      name: "Eliminar",
      function: () => {
        Delete(coment.id!);
      },
      color: "red",
    },
  ];

  const Updatecoments = (text: string) => {
    const C: ComentParams = {
      idcoment: coment.id,
      idpost: coment.idPost,
      iduser: Auth._User?.user?.id,
      coment: text,
      createDate_Js: Data._Today.toISOString(),
    };

    const model: ComentModel = {
      id: coment.id,
      idPost: coment.idPost,
      idUser: coment.idUser,
      coment: text,
      status: coment.status,
      name: coment.name,
      username: coment.username,
      idMediaDataProfile: "",
      profilePhoto: coment.profilePhoto,
      likes: coment.likes,
      liked: coment.liked,
      createDate: coment.createDate,
    };
    Coment.Update(C)
      .then((e: any) => {
        handleComentChange(model);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="Coment">
        <div className="headComent">
          <div className="coment_container">
            <div className="PerfilComent">
              {comentPerfilData ? (
                <img src={comentPerfilData.value} alt="" />
              ) : (
                <img src={UserProfile} alt="" />
              )}
            </div>
            <div className="CometBody">
              <div className="nameAndBodyOfComent">
                <strong
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/${coment.username}`, { replace: true });
                  }}
                >
                  {coment.username}{" "}
                </strong>
                <span> {coment.coment}</span>
              </div>
            </div>
          </div>
          {coment.idUser === Auth._User?.user?.id && (
            <div
              className="coment_options"
              onClick={() => setModalOptionsVisible(true)}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
          )}
        </div>
        <div className="likeContainer">
          <div className="likesComents">
            <div
              className="LikeComent"
              onClick={() => {
                setLiked(!liked);
                liked === true
                  ? likes === 1
                    ? setLikes(0)
                    : likes === 0
                    ? setLikes(0)
                    : setLikes(likes - 1)
                  : likes === 0
                  ? setLikes(1)
                  : setLikes(likes + 1);
                ActionLike(Auth._User?.user?.id!, coment.id!);
              }}
            >
              {liked && (
                <FontAwesomeIcon
                  className="iconLikeComent"
                  style={{ color: "red" }}
                  icon={faHeart}
                />
              )}
              {!liked && (
                <FontAwesomeIcon
                  className="iconLikeComent"
                  style={{ color: "grey" }}
                  icon={faHeart}
                />
              )}
            </div>
          </div>
          {likes !== 0 && (
            <div className="lettle-like">
              <span className="CantOfLikesComents">
                {likes.toString() === "1" ? `${likes} Like` : `${likes} Likes`}
              </span>
            </div>
          )}
        </div>
      </div>

      {isModalOptionsVisible && (
        <ModalOptions
          setModalVisible={setModalOptionsVisible}
          isModalVisible={isModalOptionsVisible}
          options={options}
        />
      )}
      {isModalEditVisible && (
        <EditModal
          setModalVisible={setModalEditVisible}
          isModalVisible={isModalEditVisible}
          text={coment.coment!}
          saveFunction={Updatecoments}
        />
      )}
    </>
  );
};

export default Coments;
