import React, { useEffect, useState } from "react";
import { UserPerfilModel } from "../../../../../Models/User/UserPerfilModel";
import UserProfile from "../../../../../assets/img/UserProfile.jpg";
import getHourHook from "../../../../../hooks/getHourHook";
import MediaData from "../../../../../Services/MediaData/MediaDataService";
import { useImageBankHook } from "../../../../../hooks/useImageBankHook";
import { MessageType } from "../../../../../constants/Types";

interface ILeftChatTag {
  message: string;
  createDate: Date;
  user: UserPerfilModel;
  type: string;
  idMedia: string | undefined;
}

const LeftChatTag = ({
  message,
  createDate,
  user,
  type,
  idMedia,
}: ILeftChatTag) => {
  const { getElapsedTime } = getHourHook();
  const [Photo, setPhoto] = useState<string>();

  useEffect(() => {
    if (idMedia) {
      getMedia(idMedia);
    }
  }, []);

  const { searchImage, handleImageBank } = useImageBankHook();

  const handleSrc = (id: string): string => {
    const image = searchImage(id);
    if (image.length > 0) {
      return image[0].value!;
    }
    return "";
  };

  const getMedia = async (id: any) => {
    try {
      if (id) {
        const imageBank = handleSrc(id);
        if (imageBank) {
          setPhoto(imageBank);
        } else {
          const profilePhotoResponse = await MediaData.GetById(id);
          if (profilePhotoResponse.data) {
            setPhoto(profilePhotoResponse.data.value);
            handleImageBank(profilePhotoResponse.data);
          }
        }
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  return (
    <div className="chat incoming">
      {user.profilePhoto ? (
        <img
          style={{ height: "35px", width: "35px", borderRadius: "50%" }}
          src={user?.profilePhoto}
          alt=""
        />
      ) : (
        <img src={UserProfile} alt="" />
      )}
      <div className="details">
        {type == MessageType.IMAGE && (
          <div
            style={{
              width: "300px",
            }}
          >
            {Photo && (
              <img
                style={{
                  maxWidth: "100%", 
                  maxHeight: "100%",
                  objectFit: "contain", 
                  objectPosition: "center", 
                }}
                src={Photo}
                alt="Selected"
              />
            )}
          </div>
        )}
        <p>
          {message}
          <br />
          <span className="time_date intime">
            {getElapsedTime(new Date(createDate))}
          </span>
        </p>
      </div>
    </div>
  );
};

export default LeftChatTag;
