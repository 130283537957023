import React, { useContext, useEffect } from 'react'
import InboxBox from '../Modules/Inbox/InboxBox';
import Nav from '../Modules/Nav/Nav';
import SearchBox from '../Modules/SearchBox/SearchBox';
import Space from "../Modules/Nav/SpaceBehindNav";
import { MessageParams } from '../Models/Message/MessageParams';
import { AppContext } from '../context/AppContext';

const Inbox = () => {
    useEffect(() => {
        document.title = `${process.env.REACT_APP_TITTLE_BASE} • Bandeja de Entrada`;
        return () => {
            document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
        };
    }, []);

    const Context = useContext(AppContext);
    if (!Context) {
        // Manejar el caso cuando el contexto es nulo
        return null;
    }
    const { searchVisible,
        usersConnecting,
        messages,
        sendMessage,
        lastMessageState,
        setMessages_F,

    } = Context;
    return (
        <>
            {!searchVisible && (
                <InboxBox
                    usersConnecting={usersConnecting}
                    messages={messages}
                    sendMessage={sendMessage}
                    LastMessage={lastMessageState!}
                    setMessages={setMessages_F}
                />
            )}

        </>
    )
}

export default Inbox