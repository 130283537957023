import { faChevronLeft, faChevronRight, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../context/AppContext'
import { ImageModel } from '../../../../Models/Post/ImageModel'
import { PostModel } from '../../../../Models/Post/PostModel'
import Auth from '../../../../Services/Auth/AuthService'
import Post from '../../../../Services/Post/PostService'
import Publications from '../../Publications'
import PerfilPublicationImage from '../PerfilPublication/PerfilPublicationImage'
import './ArchivedPublication.css'

const ArchivedPublication = ({ setArchivedView }: any) => {
    const [post, setPost] = useState<PostModel[]>([])
    const [PostOnView, setPostOnView] = useState<boolean>(false)
    const [indexPost, setindexPost] = useState<number>(0);
    const [SearchData, setSearchData] = useState<boolean>(true)
    const [archivedPostSection, setArchivedPostSection] = useState<number>(1);

    useEffect(() => {
        document.title = `Publicaciones archivadas (${Auth._User?.user?.username!})`;
        return () => {
            document.title = `${Auth._User?.user?.name!} (${Auth._User?.user?.username!})`;
        };
    }, []);

    useEffect(() => {
        if (SearchData) {
            getPost()
        }
    }, [SearchData])

    useEffect(() => {
        if (post[indexPost] === undefined) {
            setindexPost(indexPost - 1);
        }
    }, [post])

    const getPost = () => {
        Post.GetArchivedPost(Auth._User?.user?.id!, archivedPostSection)
            .then((e: any) => {
                setPost(e.data)
                setSearchData(false)
            })
            .catch((err: any) => {
                console.error(err)
            })
    }

    const Context = useContext(AppContext);

    if (!Context) {
        return null;
    }
    const { OnLoadPost } = Context;


    const setPostDelete = (a: any) => {
        setPost(post.filter((p: PostModel) => p.id != post[indexPost].id))
    }


    const scrollToElement = (index: number) => {
        setindexPost(index)
        setPostOnView(true)
    }
    return (
        <div className="AP_modal_box">
            <div className="AP_modal_container">
                <div className="AP_modal_container_tittle">
                    <br />
                    <h2>Publicaciones Archivadas</h2>
                    {SearchData && (
                        <div className="puntos-container">
                            <div className="punto"></div>
                            <div className="punto"></div>
                            <div className="punto"></div>
                        </div>
                    )}
                </div>
                <div className='AP_div_view_container'>
                    <div className='container container-post'>
                        {post.map((p: PostModel, index: number) => (
                            <PerfilPublicationImage index={index} scrollToElement={scrollToElement} publication={p} />
                        ))}
                    </div>

                    {PostOnView && post.length > 0 && post[indexPost] !== undefined &&
                        <div className="P_modal_box">
                            <div className="P_modal_container">
                                <div className='div_view_container'>
                                    <div className='chevron_box'>
                                        {indexPost !== 0 && (
                                            <div onClick={() => {
                                                if (indexPost > 0) {
                                                    setindexPost(indexPost - 1)
                                                }
                                            }}>
                                                <FontAwesomeIcon className='arrow_left_box' icon={faChevronLeft} />
                                            </div>)

                                        }
                                    </div>
                                    <div className="ViewModal_P">
                                        <Publications
                                            iduser={Auth._User?.user?.id}
                                            Post={[post[indexPost]]}
                                            OnLoadPost={OnLoadPost}
                                            setPost={setPostDelete}
                                            charging={false}
                                        />
                                    </div>
                                    <div className='chevron_box'>
                                        {post.length > indexPost + 1 && (
                                            <div onClick={() => { setindexPost(indexPost + 1) }}><FontAwesomeIcon className='arrow_left_box' icon={faChevronRight} /></div>
                                        )}
                                    </div>
                                </div>
                            </div>


                            <div>
                                <div onClick={() => { setPostOnView(false) }}><FontAwesomeIcon className='closeIcon' icon={faX} /></div>
                            </div>
                        </div >
                    }
                </div>
            </div>
            <div>
                <div onClick={() => { setArchivedView(false) }}><FontAwesomeIcon className='AP_closeIcon' icon={faX} /></div>
            </div>
        </div >
    )
}

export default ArchivedPublication