import React from 'react'
import { Route } from 'react-router-dom'
import { Inbox } from '../Pages'
import CustomRoutes from './CustomRoutes'

const InboxRoutes = () => {
    return (
        <CustomRoutes>
            <Route index element={<Inbox/>} />
            <Route path=":username" element={<Inbox/>} />
        </CustomRoutes>
    )
}

export default InboxRoutes