import React, { useEffect, useState } from "react";
import UserProfile from "../../../../../assets/img/UserProfile.jpg";
import GroupProfile from "../../../../../assets/img/GroupProfile.jpg";
import { CurrencyConversation } from "../../../../../Models/Message/CurrencyConversation";
import { useNavigate } from "react-router-dom";
import "./InboxUser.css";
import Data from "../../../../../Services/Data/Data";
import { ImageModel } from "../../../../../Models/Post/ImageModel";
import MediaData from "../../../../../Services/MediaData/MediaDataService";
import { useImageBankHook } from "../../../../../hooks/useImageBankHook";
import getHourHook from "../../../../../hooks/getHourHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { MessageType } from "../../../../../constants/Types";

interface ICurrencyConversation {
  CC: CurrencyConversation;
  usersConnecting: any;
}

const InboxUser = ({ CC, usersConnecting }: ICurrencyConversation) => {
  const navigate = useNavigate();
  const { getElapsedTime } = getHourHook();
  const [userProfilePhoto, setuserProfilePhoto] = useState<string>();

  const active = CC.isDirect ? usersConnecting.includes(CC.username, 0) : false;

  useEffect(() => {
    if (CC.idMediaDataProfile) {
      getProfile(CC.idMediaDataProfile);
    }
  }, []);

  const { searchImage, handleImageBank } = useImageBankHook();

  const handleSrc = (id: string): string => {
    const image = searchImage(id);
    if (image.length > 0) {
      return image[0].value!;
    }
    return "";
  };

  const getProfile = async (id: any) => {
    try {
      if (id) {
        const imageBank = handleSrc(id);
        if (imageBank) {
          setuserProfilePhoto(imageBank);
        } else {
          const profilePhotoResponse = await MediaData.GetById(id);
          if (profilePhotoResponse.data) {
            setuserProfilePhoto(profilePhotoResponse.data.value);
            handleImageBank(profilePhotoResponse.data);
          }
        }
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  return (
    <div
      className="InboxUser"
      onClick={() =>
        CC.isDirect
          ? navigate(`/Inbox/${CC.username}`)
          : navigate(`/Inbox/Group/${CC.idChat}`)
      }
    >
      <div className="imgboxconversation">
        {CC.isDirect ? (
          userProfilePhoto ? (
            <img src={userProfilePhoto} alt="" />
          ) : (
            <img src={UserProfile} alt="" />
          )
        ) : (
          <img src={GroupProfile} alt="" />
        )}
        {active && <div className="greenPointconversation"></div>}
      </div>
      <div className="nameconversationuser">
        <b>{CC.isDirect ? CC.name : CC.nameGroup}</b>
        <br />
        <div className="d-play">
          <div className="d-message">
            <span className="overflow-ellipsis">
              {CC.messageType == MessageType.IMAGE && (
                <FontAwesomeIcon
                  style={{ marginRight: "6px" }}
                  icon={faCamera}
                />
              )}
              {CC.messageValue ? CC.messageValue : "Foto"}
            </span>
          </div>
          <div>
            <span>{getElapsedTime(new Date(CC.createDate!))}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboxUser;
