import React, { useEffect, useState } from "react";
import "./UserPerfil.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronDown,
  faEnvelopeSquare,
  faPencil,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import UserProfile from "../../assets/img/UserProfile.jpg";
import UserCover from "../../assets/img/UserCover.jpg";
import { UserPerfilModel } from "../../Models/User/UserPerfilModel";
import Auth from "../../Services/Auth/AuthService";
import Follow from "../../Services/Follow/FollowService";
import { useNavigate } from "react-router-dom";
import CropperC from "../Cropper/CropperC";
import PerfilOptions from "../PostOptions/PerfilOptions";
import ArchivedPublication from "../Publications/Publication/ArchivedPublication/ArchivedPublication";
import FollowsListModal from "../Modals/FollowsListModal";

interface IUserPerfil {
  userData: UserPerfilModel | undefined
  cantOfFollow: number
  setCantOfFollow: Function
  isActiveSpinner: boolean
  cantOfFollowings: number
  setCantOfFollowings: Function
  isfollow: boolean
  setIsFollow: Function
}

const UserPerfil = (props: IUserPerfil) => {
  const navigate = useNavigate()

  const [ChagedPhofilePhoto, setChagedPhofilePhoto] = useState<string>("")
  const [ChagedCoverPhoto, setChagedCoverPhoto] = useState<string>("")

  const [aspectRatio, setAspectRatio] = useState<number>(0)
  const [inputValue, setInputValue] = useState<string>("")
  const ImageRef = React.createRef<HTMLInputElement>()
  const [image, setImage] = useState<any>(null)

  const [ViewPPO, setViewPPO] = useState<boolean>(false)
  const [ArchivedView, setArchivedView] = useState<boolean>(false)
  const [followsModalView, setFollowsModalView] = useState<boolean>(false)
  const [followsModaltab, setFollowsModaltab] = useState<boolean>(false)

  const setFollow = (idUser: string, isFollow: boolean) => {
    Follow.FollowUnFollow(Auth._User?.user?.id, idUser, isFollow)
      .then((res: any) => {
      })
      .catch((e: any) => {
        console.error(e);
      });

    props.isfollow
      ? props.setCantOfFollow(props.cantOfFollow - 1)
      : props.setCantOfFollow(props.cantOfFollow + 1);
    props.setIsFollow(!props.isfollow);
  };


  const setFollowModal = (idUser: string, isFollowModal: boolean) => {
    Follow.FollowUnFollow(Auth._User?.user?.id, idUser, isFollowModal)
      .then((res: any) => {
      })
      .catch((e: any) => {
        console.error(e);
      });
    props.userData?.user?.id == Auth._User?.user?.id && (
      isFollowModal
        ? (props.setCantOfFollowings(props.cantOfFollowings - 1))
        : (props.setCantOfFollowings(props.cantOfFollowings + 1))
    )
  };


  const setPhoto = (photo: string) => {
    console.log(photo);
    aspectRatio === 1 ? setChagedPhofilePhoto(photo) : setChagedCoverPhoto(photo);
  }

  const OnImageChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      setInputValue(e.target.value);
      var img = e.target.files[0];
      setImage(img);
    }
  };

  useEffect(() => {
    // Cambiar el título de la página cuando el componente se monta
    document.title = `${props.userData?.user?.name} (${props.userData?.user?.username})`;

    // También puedes restablecer el título cuando el componente se desmonta
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, []);

  return (
    <>
      <div className="container col-md-7 UserPerfi">
        <div className="PortadaUser">
          {ChagedCoverPhoto ? <img src={ChagedCoverPhoto} alt="" /> : props.userData?.coverPhoto ? <img src={props.userData?.coverPhoto} alt="" /> : <img src={UserCover} alt="" />}
          {props.userData?.user?.id == Auth._User?.user?.id && (
            <>
              <FontAwesomeIcon id="editportadaicon" icon={faPencil} onClick={() => {
                setAspectRatio(19 / 6);
                ImageRef.current?.click();
              }} />
              <FontAwesomeIcon title="Opciones" id="settingsicon" onClick={() => setViewPPO(true)} icon={faCircleChevronDown} />
            </>
          )}
        </div>
        <div className="PerfilUser">
          {ChagedPhofilePhoto ? <img src={ChagedPhofilePhoto} alt="" /> : props.userData?.profilePhoto ? <img src={props.userData?.profilePhoto} alt="" /> : <img src={UserProfile} alt="" />}
          {props.userData?.user?.id == Auth._User?.user?.id && (
            <FontAwesomeIcon id="editperfilicon" icon={faPencil} onClick={() => {
              setAspectRatio(1);
              ImageRef.current?.click();
            }} />
          )}
        </div>
        <input
          type="file"
          name="Post"
          id="PostId"
          accept="image/*"
          style={{ display: "none" }}
          ref={ImageRef}
          value={inputValue}
          onChange={(e) => {
            OnImageChange(e);
          }}
        />
        <div className="InfoUserPerfil">
          <h4>{props.userData?.user?.name}</h4>
          <b>@{props.userData?.user?.username}</b>
          {props.userData?.user?.id != Auth._User?.user?.id && (
            <>
              {props.isfollow && (
                <div className="followBox">
                  <div className="followed" onClick={() => setFollow(props.userData?.user?.id!, props.isfollow)}>
                    <FontAwesomeIcon
                      className="iconfollow"
                      icon={faUserCheck}
                    />
                  </div>
                  <div className="buttonsFolloed" onClick={() => {
                    navigate(`/Inbox/${props.userData?.user?.username}`, { replace: true })
                  }}
                  >
                    <FontAwesomeIcon
                      className="iconMsgPerfil"
                      icon={faEnvelopeSquare}
                    />
                    <b className="sendMsg">Enviar Mensaje</b>
                  </div>
                </div>
              )}

              {!props.isfollow && (
                <div className="NOfollowBox">
                  <div className="follow" onClick={() => setFollow(props.userData?.user?.id!, props.isfollow)}>
                    <b>Seguir</b>
                  </div>
                </div>
              )}
            </>
          )}

          <div>
            <b style={{ cursor: 'pointer' }}
              onClick={() => {
                setFollowsModalView(true)
                setFollowsModaltab(true)
              }}>{props.cantOfFollow} Seguidores </b>
            <span>|</span>
            <b style={{ cursor: 'pointer' }}
              onClick={() => {
                setFollowsModalView(true)
                setFollowsModaltab(false)
              }}> {props.cantOfFollowings} Seguidos</b>
          </div>
          <div>
            <span>{props.userData?.user?.perfilData?.presentation}</span>
          </div>
        </div>
        <hr />
      </div>

      {ViewPPO && (
        <PerfilOptions setViewPPO={setViewPPO} setArchivedView={setArchivedView} />
      )}

      {ViewPPO && (
        <PerfilOptions setViewPPO={setViewPPO} setArchivedView={setArchivedView} />
      )}

      {ArchivedView && (
        <ArchivedPublication setArchivedView={setArchivedView} />
      )}

      {followsModalView && (
        <FollowsListModal setFollowsModalView={setFollowsModalView} userID={props.userData?.user?.id!} tab={followsModaltab} setFollowHandle={setFollowModal} />
      )}

      {image && (
        <CropperC
          src={URL.createObjectURL(image)}
          setImage={setImage}
          setInputValue={setInputValue}
          aspectRatio={aspectRatio}
          optionCut={2}
          setPhotos={setPhoto}
        />
      )
      }
    </>
  );
};

export default UserPerfil;
