import './PhotoMosaic.css';
import { PostModel } from '../../../../Models/Post/PostModel';
import Mosaic from './Mosaic';

const PhotoMosaic = ({ posts }: any) => {
    return (
        <div className="photo-mosaic col-md-10">
            {posts.map((e: PostModel, index: number) => {
                let size = '';
                if (index % 5 === 0) {
                    size = 'large';
                } else if (index % 7 === 0) {
                    size = 'medium';
                } else {
                    size = 'small';
                }
                return (<Mosaic size={size} index={index} publication={e} />)
            })}
        </div>
    );
};

export default PhotoMosaic;
