import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Auth from '../../Services/Auth/AuthService'
import Follow from '../../Services/Follow/FollowService'
import UserProfile from "../../assets/img/UserProfile.jpg";
import './FollowsListModal.css'
import MediaData from '../../Services/MediaData/MediaDataService'

const FollowsListModal = ({ setFollowsModalView, userID, tab, setFollowHandle }: any) => {
    const navigate = useNavigate();
    const [followers, setFollowers] = useState<any>([])
    const [followins, setFollowins] = useState<any>([])

    const [followersPag, setFollowersPag] = useState<number>(1)
    const [followinsPag, setFollowinsPag] = useState<any>(1)

    const [followtab, setFollowtab] = useState<boolean>(tab)
    useEffect(() => {
        getFollower(userID, followersPag)
        getFolloing(userID, followinsPag)
    }, [])


    const updateProfilePhotos = async (userList: any, setList: Function) => {
        const updatedUsers = await Promise.all(userList.map(async (u: any) => {
            if (u.perfilData?.idMediaDataProfile) {
                try {
                    const pPDta: any = await MediaData.GetById(u.perfilData?.idMediaDataProfile);
                    u.profilePhoto = pPDta.data.value;
                } catch (error) {
                    console.error(`Error fetching media data for user ${u.id}:`, error);
                    u.profilePhoto = "";
                }
            } else {
                u.profilePhoto = "";
            }
            return u;
        }));

        setList(updatedUsers);
    };

    const getFollower = (id: string, section: number) => {
        Follow.GetFollowerList(id, section)
            .then((e: any) => {
                updateProfilePhotos(e.data,setFollowers)
            })
            .catch((err: any) => {
                console.error(err);
            })
    }

    const getFolloing = (id: string, section: number) => {
        Follow.GetFollowingList(id, section)
            .then((e: any) => {
                updateProfilePhotos(e.data,setFollowins)
            })
            .catch((err: any) => {
                console.error(err);
            })
    }

    const setFollowHandleL = (idUser: number, isfollow: boolean) => {
        setFollowHandle(idUser, isfollow)
    };

    const toggleFollow = (user: any) => {
        user.isFollow = !user.isFollow;
        setFollowers([...followers]);
    };

    return (
        <div className="Follow_modal_box">
            <div className="Follow_modal_container">
                <div className="Follow_modal_button">
                    <div className="Follow_OptionsList">
                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: 'auto' }}>
                            <div onClick={() => setFollowtab(true)} className='item-Follow' style={followtab ? { borderBottom: '3px solid var(--hover)' } : { borderBottom: '' }}><b>Seguidores</b></div>
                            <div onClick={() => setFollowtab(false)} className='item-Follow' style={!followtab ? { borderBottom: '3px solid var(--hover)' } : { borderBottom: '' }}><b>Seguidos</b></div>
                        </div>
                    </div>
                    <div>
                        <div onClick={() => { setFollowsModalView(false) }}><FontAwesomeIcon className='AP_closeIcon' icon={faX} /></div>
                    </div>
                </div>
                <hr />
                <div className='container_targets'>
                    {followtab && (
                        <div style={{ marginBottom: '30px' }}>
                            {followers.map((u: any) => (
                                <div
                                    key={u.username}
                                    className="search_conteiner_box"
                                >
                                    <div className="img_search_box"
                                        onClick={() => {
                                            navigate(`/${u.username}`);
                                        }}>
                                        {u.profilePhoto ? <img src={u.profilePhoto} alt="" /> : <img src={UserProfile} alt="" />}
                                    </div>
                                    <div className="nameAndUserSeach"
                                        onClick={() => {
                                            navigate(`/${u.username}`);
                                        }}>
                                        <b>{u.name}</b>
                                        <br />
                                        <span>@{u.username}</span>
                                    </div>
                                    {u.username != Auth._User?.user?.username && (
                                        u.isFollow
                                            ? <div className="NOfollowBox button_follow">
                                                <div className="follow"
                                                    onClick={() => {
                                                        setFollowHandleL(u.id, u.isFollow)
                                                        toggleFollow(u)
                                                    }}>
                                                    <b>Seguido</b>
                                                </div>
                                            </div>
                                            : <div className="NOfollowBox button_follow">
                                                <div className="follow"
                                                    onClick={() => {
                                                        setFollowHandleL(u.id, u.isFollow)
                                                        toggleFollow(u)
                                                    }}>
                                                    <b>Seguir</b>
                                                </div>
                                            </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    {!followtab && (
                        <div style={{ marginBottom: '30px' }}>
                            {followins.map((u: any) => (
                                <div
                                    key={u.username}
                                    className="search_conteiner_box"
                                >
                                    <div className="img_search_box"
                                        onClick={() => {
                                            navigate(`/${u.username}`);
                                        }}>
                                        {u.profilePhoto ? <img src={u.profilePhoto} alt="" /> : <img src={UserProfile} alt="" />}
                                    </div>
                                    <div className="nameAndUserSeach"
                                        onClick={() => {
                                            navigate(`/${u.username}`);
                                        }}>
                                        <b>{u.name}</b>
                                        <br />
                                        <span>@{u.username}</span>
                                    </div>
                                    {u.username != Auth._User?.user?.username && (
                                        u.isFollow
                                            ? <div className="NOfollowBox button_follow">
                                                <div className="follow"
                                                    onClick={() => {
                                                        setFollowHandleL(u.id, u.isFollow)
                                                        toggleFollow(u)
                                                    }}>
                                                    <b>Seguido</b>
                                                </div>
                                            </div>
                                            : <div className="NOfollowBox button_follow">
                                                <div className="follow"
                                                    onClick={() => {
                                                        setFollowHandleL(u.id, u.isFollow)
                                                        toggleFollow(u)
                                                    }}>
                                                    <b>Seguir</b>
                                                </div>
                                            </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}

export default FollowsListModal