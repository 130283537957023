import React, { useState } from 'react'
import { FillPostParams } from '../../Models/Post/FillPostParams'
import { PostModel } from '../../Models/Post/PostModel'
import { PostParams } from '../../Models/Post/PostParams'
import IndexPostCharger from '../Charger/IndexPostCharger/IndexPostCharger'
import IndexPostOptions from '../PostOptions/IndexPostOptions'
import Publication from './Publication/Publication'
import PublicationOnLoad from './Publication/PublicationOnLoad'

interface IPublications {
    iduser: string | undefined
    Post: PostModel[]
    OnLoadPost: PostParams | undefined
    setPost: Function
    status?: number | 9;
    charging: boolean
}

const Publications = (props: IPublications) => {
    const [watchModalOP, setWatchModalOP] = useState<boolean>(false)
    const [OPData, setOPData] = useState<PostModel>()


    const setFillPost = (fP: FillPostParams) => {

        const fillPost = props.Post.map((p: PostModel) => {
            if (p.id === fP.id) {
                p.liked = fP.liked;
                p.likes = fP.amountOfLikes;
                p.comments = fP.amountOfComents;
            }
            return p;
        })

        props.setPost(fillPost)
    }


    const handlePostChange = (modifiedPost: PostModel) => {
        const updatedComents = props.Post.map((post) => {
            if (post.id === modifiedPost.id) {
                return modifiedPost;
            }
            return post;
        });
        props.setPost(updatedComents);
    };


    const FilterPosts = (id: string) => {
        props.setPost(props.Post.filter((p: PostModel) => p.id !== id))
    }

    return (
        <>
            {props.OnLoadPost && (
                <PublicationOnLoad publication={props.OnLoadPost} />
            )}
            {props.Post.map((p: PostModel, key: number) => (
                <Publication key={key} publication={p} setViewOP={setWatchModalOP} setOPData={setOPData} setFillPost={setFillPost} />
            ))}
            {watchModalOP && (
                <IndexPostOptions setViewOP={setWatchModalOP} Data={OPData} FilterData={FilterPosts} handlePostChange={handlePostChange} whereCode={true} />
            )}
            {props.charging && <IndexPostCharger />}
        </>
    )
}

export default Publications