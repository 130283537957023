import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ModalOptions.css";
import React, { useContext, useState } from "react";
import { PostModel } from "../../Models/Post/PostModel";
import Status from "../../Services/Status/StatusService";
import History from "../../Services/History/HistoryService";
import { AppContext } from "../../context/AppContext";
import EditModal from "./EditModal";
import { PostParams } from "../../Models/Post/PostParams";
import Auth from "../../Services/Auth/AuthService";
import Post from "../../Services/Post/PostService";
import { ModalOptionModel } from "../../Models/data/ModalOptionModel";

interface IPostOPtions {
  setModalVisible: Function;
  isModalVisible: boolean;
  options: ModalOptionModel[];
}

const ModalOptions = ({
  setModalVisible,
  isModalVisible,
  options,
}: IPostOPtions) => {
  return (
    <div className="OptionsModalBox">
      <div className="OptionsModalContainer">
        <div className="OptionsModalContainerBox">
          <div
            onClick={() => {
              setModalVisible(!isModalVisible);
            }}
            className="closeBox"
          >
            <FontAwesomeIcon icon={faX} />
          </div>
          <div className="OptionsList">
            {options.map((o: ModalOptionModel, index: number) => (
              <div key={index} className="OptionItems" onClick={() => o.function && o.function()}>
                <b style={{ color: o.color }}>{o.name}</b>
              </div>
            ))}
            <div
              className="OptionItems"
              onClick={() => setModalVisible(!isModalVisible)}
            >
              <b>Cancelar</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalOptions;
