import React, { useEffect, useState } from "react";
import UserProfile from "../../../../../assets/img/UserProfile.jpg";
import GroupProfile from "../../../../../assets/img/GroupProfile.jpg";
import { CurrencyConversation } from "../../../../../Models/Message/CurrencyConversation";
import { useNavigate } from "react-router-dom";
import "./InboxUser.css";
import Data from "../../../../../Services/Data/Data";
import { ImageModel } from "../../../../../Models/Post/ImageModel";
import MediaData from "../../../../../Services/MediaData/MediaDataService";
import { useImageBankHook } from "../../../../../hooks/useImageBankHook";

interface ICurrencyConversation {
  CC: CurrencyConversation;
  usersConnecting: any;
}

const InboxUser = ({ CC, usersConnecting }: ICurrencyConversation) => {
  const navigate = useNavigate();
  const [userProfilePhoto, setuserProfilePhoto] = useState<string>();

  const active = CC.isDirect ? usersConnecting.includes(CC.username, 0) : false;

  const todayDate = new Date();
  const todayStr = todayDate.toString().substr(0, 15);
  const AyerDate = new Date();
  AyerDate.setDate(AyerDate.getDate() - 1);
  const Ayer = AyerDate;

  useEffect(() => {
    if (CC.idMediaDataProfile) {
      getProfile(CC.idMediaDataProfile);
    }
  }, []);

  const { searchImage, handleImageBank } = useImageBankHook();

  const handleSrc = (id: string): string => {
    const image = searchImage(id);
    if (image.length > 0) {
      return image[0].value!;
    }
    return "";
  };

  const getProfile = async (id: any) => {
    try {
      if (id) {
        const imageBank = handleSrc(id);
        if (imageBank) {
          setuserProfilePhoto(imageBank);
        } else {
          const profilePhotoResponse = await MediaData.GetById(id);
          if (profilePhotoResponse.data) {
            setuserProfilePhoto(profilePhotoResponse.data.value);
            handleImageBank(profilePhotoResponse.data);
          }
        }
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  // const day = parseInt(`${props.CC.createDate_Js?.substr(8, 2)}`);
  // const month = parseInt(`${props.CC.createDate_Js?.substr(5, 2)}`) - 1;
  // const year = parseInt(`${props.CC.createDate_Js?.substr(0, 4)}`);
  // const fechaDate = new Date(year, month, day);
  // const fecha = fechaDate.toString().substr(0, 15);

  return (
    <div
      className="InboxUser"
      onClick={() =>
        CC.isDirect
          ? navigate(`/Inbox/${CC.username}`)
          : navigate(`/Inbox/Group/${CC.idChat}`)
      }
    >
      <div className="imgboxconversation">
        {CC.isDirect ? (
          userProfilePhoto ? (
            <img src={userProfilePhoto} alt="" />
          ) : (
            <img src={UserProfile} alt="" />
          )
        ) : (
          <img src={GroupProfile} alt="" />
        )}
        {active && <div className="greenPointconversation"></div>}
      </div>
      <div className="nameconversationuser">
        <b>{CC.isDirect ? CC.name : CC.nameGroup}</b>
        <br />
        <div className="d-play">
          <div className="d-message">
            <span className="overflow-ellipsis">{CC.messageValue}</span>
          </div>
          <div>
            <span>{CC.createDate}</span>
            {/* <span className='dateconversation'>{todayStr === fecha.substr(0, 15) ? parseInt(`${props.CC.createDate_Js?.substr(11, 2)}`) !== undefined && parseInt(`${props.CC.createDate_Js?.substr(11, 2)}`) > 12
              ? parseInt(`${props.CC.createDate_Js?.substr(11, 2)}`) - 12 + `:${props.CC.createDate_Js?.substr(14, 2)}pm`
              : `${props.CC.createDate_Js?.substr(11, 2)}` === '00' ? `12:${props.CC.createDate_Js?.substr(14, 2)}am` : `${props.CC.createDate_Js?.substr(11, 5)}am` : Ayer.toString().substr(0, 15) === fecha.substr(0, 15) ? `Ayer` : parseInt(todayStr.substr(11, 4)) === year ? fecha.substr(4, 6) : `${fecha.substr(4, 3)} ${fecha.substr(11, 4)}`}</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboxUser;
