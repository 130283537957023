import { useContext, useEffect } from "react";
import UserPerfil from "../Modules/UserPerfil/UserPefil";
import Footer from "../Modules/Footer/Footer";
import ChargerSlice from "../Modules/ChargerSlice/ChargerSlice";
import { Link, useParams } from "react-router-dom";
import Auth from "../Services/Auth/AuthService";
import PerfilPublication from "../Modules/Publications/Publication/PerfilPublication/PerfilPublication";
import { AppContext } from "../context/AppContext";
import { PerfilPostCharger } from "../Modules/Charger/PerfilPostCharger/PerfilPostCharger";

const Perfil = () => {
  let { username } = useParams();
  // let UserLog = Auth._User;

  useEffect(() => {
    getPerfilPost(username!)
    getPerfilDta(username!)
  }, [username]);

  const Context = useContext(AppContext);
  if (!Context) {
    // Manejar el caso cuando el contexto es nulo
    return null;
  }
  const { searchVisible,
    buttonsVisible,
    setButtonsVisible_F,
    ConcatMyHistorys,
    setPhoto,
    setOnLoadPhoto,
    setFooterViewer_F,
    footerViewer,
    perfilPost,
    setPerfilPost,
    perfiluser,
    getPerfilPost,
    getPerfilDta,
    cantOfFollow,
    setCantOfFollow,
    cantOfFollowings,
    setCantOfFollowings,
    isActiveSpinner,
    isPostCharging,
    isfollow,
    setIsFollow
  } = Context;





  return (
    <>
      {isActiveSpinner && <ChargerSlice />}
      {!isActiveSpinner && (
        <>
          {!searchVisible && (
            <>
              {perfiluser?.user?.id != ""
                ? (<>
                  <UserPerfil
                    userData={perfiluser}
                    cantOfFollow={cantOfFollow!}
                    setCantOfFollow={setCantOfFollow}
                    cantOfFollowings={cantOfFollowings!}
                    setCantOfFollowings={setCantOfFollowings}
                    isActiveSpinner={isActiveSpinner}
                    isfollow={isfollow}
                    setIsFollow={setIsFollow}
                  />
                  {/* <HistoryComponet historyList={props.historyList} /> */}
                  <Footer
                    buttonVisible={buttonsVisible}
                    setButtonsVisible={setButtonsVisible_F}
                    setPhotos={setPhoto}
                    setOnLoadPhoto={setOnLoadPhoto}
                    setFooterViewer={setFooterViewer_F}
                    footerViewer={footerViewer}
                    setMyHistorys={ConcatMyHistorys}
                  />
                    <PerfilPublication posts={perfilPost} setPost={setPerfilPost} />
                    {isPostCharging && <PerfilPostCharger />}
                </>)
                : (<div style={{ textAlign: 'center', marginTop: '100px' }}>
                  <h3>Usuario no encontrado</h3>
                  <b>Este usario no existe o que se haya eliminado la cuenta. <Link to="/">
                    <span>Volver a Aycoro.</span>
                  </Link></b>
                </div>)
              }

            </>
          )}
        </>
      )}
    </>
  );
};

export default Perfil;
