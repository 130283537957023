import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserProfile from "../../assets/img/UserProfile.jpg";
import "./SearchBox.css";
import { SearchCharger } from "../Charger/SearchCharger/SearchCharger";
import { UserPerfilModel } from "../../Models/User/UserPerfilModel";

interface ISearchItem {
    user: any
}

const SearchItem = ({ user }: ISearchItem) => {
    const navigate = useNavigate();
    return (
        <div
            key={user.user?.id}
            className="search_conteiner_box"
            onClick={() => {
                navigate(`/${user.username}`, { replace: true });
            }}
        >
            <div className="img_search_box">
                {user.profilePhoto ? <img src={user.profilePhoto} alt="" /> : <img src={UserProfile} alt="" />}
            </div>
            <div className="nameAndUserSeach">
                <b>{user.name}</b>
                <br />
                <span>@{user.username}</span>
            </div>
        </div>
    );
};

export default SearchItem;
