import React, { useContext, useEffect, useState } from "react";
import Auth from "../../Services/Auth/AuthService";
import Validate from "../../Services/Validate/ValidateService";
import FooterBlog from "../FooterBlog/FooterBlog";
import {
  faCheckCircle,
  faExclamationCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Config.css";
import Changes from "../../Services/Changes/ChangesService";
import { AppContext } from "../../context/AppContext";
import Notify from "../Notify/Notify";
import { UserModel } from "../../Models/User/UserModel";
import { UserPerfilModel } from "../../Models/User/UserPerfilModel";

const Config = () => {
  const [error, setError] = useState<string>("");
  const [errorIsActive, setErrorIsActive] = useState<boolean>(false);
  const [editItems, setEditItems] = useState<number>(0);
  const [configOPtion, setConfigOPtion] = useState<number>(0);

  const [name, setName] = useState<string>(Auth._User?.user?.name!);
  const [username, setUsername] = useState<string>(Auth._User?.user?.username!);
  const [presentacion, setPresentation] = useState<string | undefined>(
    Auth._User?.user?.perfilData?.presentation!
  );
  const [email, setEmail] = useState<string | undefined>(Auth._User?.user?.email!);
  const [phone, setPhone] = useState<string | undefined>(Auth._User?.user?.phone!);

  const [ActualPassword, setActualPassword] = useState<string | undefined>(undefined);
  const [NewPassword, setNewPassword] = useState<string | undefined>(undefined);
  const [ReplyNewpPassword, setReplyNewpPassword] = useState<string | undefined>(undefined);

  const [VisibleActualPassword, setVisibleActualPassword] = useState<boolean>(false);
  const [VisibleNewPassword, setVisibleNewPassword] = useState<boolean>(false);
  const [VisibleReplyNewpPassword, setVisibleReplyNewpPassword] = useState<boolean>(false);

  const [validateEmail, setValidateEmail] = useState<boolean>(false);
  const [validateUsername, setValidateUsername] = useState<boolean>(false);

  const [modalConfirmation, setModalConfirmation] = useState<[boolean, string]>(
    [false, ""]
  );

  useEffect(() => {
    document.title = `${process.env.REACT_APP_TITTLE_BASE} • Configuracion`;
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, []);

  const Context = useContext(AppContext);

  if (!Context) {
    // Manejar el caso cuando el contexto es nulo
    return null;
  }
  const { closeConnection } = Context;

  const toggleVisibleActualPassword = () => {
    setVisibleActualPassword(!VisibleActualPassword);
  };

  const toggleVisibleNewPassword = () => {
    setVisibleNewPassword(!VisibleNewPassword);
  };

  const toggleVisibleReplyNewpPassword = () => {
    setVisibleReplyNewpPassword(!VisibleReplyNewpPassword);
  };

  const ValidateEmail = () => {
    if (email !== undefined && email !== "") {
      Validate.validateEmail(email)
        .then((res: any) => {
          if (res.data) {
            setValidateEmail(true);
            setErrorIsActive(false);
          } else {
            setValidateEmail(false);
            setError("Email no disponible");
            setErrorIsActive(true);
          }
        })
        .catch((res: any) => {
          console.log(res);
        });
    } else {
      setValidateUsername(false);
    }
  };

  const VerifyUsername = () => {
    if (username !== undefined && username !== "") {
      if (ValidateUsername(username)) {
        Validate.validateUsername(username)
          .then((res: any) => {
            if (res.data) {
              setValidateUsername(true);
              setErrorIsActive(false);
            } else {
              setValidateUsername(false);
              setError("Usuario no disponible");
              setErrorIsActive(true);
            }
          })
          .catch((err: any) => {
            console.error(err);
          });
      }
    } else {
      setValidateUsername(false);
    }
  };

  const ValidateUsername = (username: string) => {
    if (username.length < 7 || username.length > 30) {
      setValidateUsername(false);
      setError("Usuario, maximo 30 caracteres, minimo 7 caracteres");
      setErrorIsActive(true);
      return false;
    }

    if (/^\d+$/.test(username)) {
      setValidateUsername(false);
      setError("El usuario no puede contener solo números");
      setErrorIsActive(true);
      return false;
    }

    if (!/^[a-zA-Z0-9_.-]+$/.test(username)) {
      setValidateUsername(false);
      setError("El usuario no puede contener caracteres especiales");
      setErrorIsActive(true);
      return false;
    }

    setValidateUsername(true);
    setErrorIsActive(false);
    return true;
  }

  const changName = () => {
    Changes.Name(name!, Auth._User?.user?.id!)
      .then((e: any) => {
        if (e.status === 200) {
          const User: UserPerfilModel = {
            user: {
              id : Auth._User?.user?.id,
              name: name,
              username: Auth._User?.user?.username,
              email: Auth._User?.user?.email,
              password: undefined,
              phone: Auth._User?.user?.phone,
              birthday: Auth._User?.user?.birthday,
              gender: Auth._User?.user?.gender,
              status: Auth._User?.user?.status,
              verify: Auth._User?.user?.verify,
              perfilData: {
                presentation: Auth._User?.user?.perfilData?.presentation,
                idMediaDataProfile: Auth._User?.user?.perfilData?.idMediaDataProfile,
                idMediaDataCover: Auth._User?.user?.perfilData?.idMediaDataCover
              },
              createDate: Auth._User?.user?.createDate
            },
            isFollow: Auth._User?.isFollow,
            profilePhoto: Auth._User?.profilePhoto,
            coverPhoto: Auth._User?.coverPhoto,
            seguidos: Auth._User?.seguidos,
            seguidores: Auth._User?.seguidores
          };
          Auth.setUser(User);
          Notify(e.data, 'El nombre')
          setEditItems(0);
        }
        else {
          Notify(e.data, 'El nombre')
        }
      })
      .catch((err: any) => {
        Notify(111111, 'El nombre')
        console.error(err);
      });

  };

  const changeUsername = () => {
    Changes.Username(username!, Auth._User?.user?.id!)
      .then((e: any) => {
        if (e.status === 200) {
          const User: UserPerfilModel = {
            user: {
              id : Auth._User?.user?.id,
              name: Auth._User?.user?.name,
              username: username,
              email: Auth._User?.user?.email,
              password: undefined,
              phone: Auth._User?.user?.phone,
              birthday: Auth._User?.user?.birthday,
              gender: Auth._User?.user?.gender,
              status: Auth._User?.user?.status,
              verify: Auth._User?.user?.verify,
              perfilData: {
                presentation: Auth._User?.user?.perfilData?.presentation,
                idMediaDataProfile: Auth._User?.user?.perfilData?.idMediaDataProfile,
                idMediaDataCover: Auth._User?.user?.perfilData?.idMediaDataCover
              },
              createDate: Auth._User?.user?.createDate
            },
            isFollow: Auth._User?.isFollow,
            profilePhoto: Auth._User?.profilePhoto,
            coverPhoto: Auth._User?.coverPhoto,
            seguidos: Auth._User?.seguidos,
            seguidores: Auth._User?.seguidores
          };
          Auth.setUser(User);
          Notify(e.data, 'El usuario')
          setEditItems(0);
        } else {
          Notify(e.data, 'El usuario')
        }
      })
      .catch((err: any) => {
        Notify(111111, 'El usuario')
        console.error(err);
      });
  };

  const changePresentation = () => {    
    Changes.Presentation(presentacion!, Auth._User?.user?.id!)
      .then((e: any) => {
        if (e.status === 200) {
          const User: UserPerfilModel = {
            user: {
              id : Auth._User?.user?.id,
              name: Auth._User?.user?.name,
              username: Auth._User?.user?.username,
              email: Auth._User?.user?.email,
              password: undefined,
              phone: Auth._User?.user?.phone,
              birthday: Auth._User?.user?.birthday,
              gender: Auth._User?.user?.gender,
              status: Auth._User?.user?.status,
              verify: Auth._User?.user?.verify,
              perfilData: {
                presentation: presentacion,
                idMediaDataProfile: Auth._User?.user?.perfilData?.idMediaDataProfile,
                idMediaDataCover: Auth._User?.user?.perfilData?.idMediaDataCover
              },
              createDate: Auth._User?.user?.createDate
            },
            isFollow: Auth._User?.isFollow,
            profilePhoto: Auth._User?.profilePhoto,
            coverPhoto: Auth._User?.coverPhoto,
            seguidos: Auth._User?.seguidos,
            seguidores: Auth._User?.seguidores
          };
          Auth.setUser(User);
          Notify(e.data, 'La presentacion')
          setEditItems(0);
        } else {
          Notify(e.data, 'La presentacion')
        }
      })
      .catch((err: any) => {
        Notify(111111, 'La presentacion')
        console.error(err);
      });
  };

  const changePhone = () => {
    Changes.Phone(phone!, Auth._User?.user?.id!)
      .then((e: any) => {
        if (e.status === 200) {
          const User: UserPerfilModel = {
            user: {
              id : Auth._User?.user?.id,
              name: Auth._User?.user?.name,
              username: Auth._User?.user?.username,
              email: Auth._User?.user?.email,
              password: undefined,
              phone: phone,
              birthday: Auth._User?.user?.birthday,
              gender: Auth._User?.user?.gender,
              status: Auth._User?.user?.status,
              verify: Auth._User?.user?.verify,
              perfilData: {
                presentation: Auth._User?.user?.perfilData?.presentation,
                idMediaDataProfile: Auth._User?.user?.perfilData?.idMediaDataProfile,
                idMediaDataCover: Auth._User?.user?.perfilData?.idMediaDataCover
              },
              createDate: Auth._User?.user?.createDate
            },
            isFollow: Auth._User?.isFollow,
            profilePhoto: Auth._User?.profilePhoto,
            coverPhoto: Auth._User?.coverPhoto,
            seguidos: Auth._User?.seguidos,
            seguidores: Auth._User?.seguidores
          };
          Auth.setUser(User);
          Notify(e.data, 'El numero telefonico')
          setEditItems(0);
        } else {
          Notify(e.data, 'El numero telefonico')
        }
      })
      .catch((err: any) => {
        Notify(111111, 'El numero telefonico')
        console.error(err);
      });
  };

  const changeEmail = () => {
    Changes.Email(email!, Auth._User?.user?.id!)
      .then((e: any) => {
        if (e.status === 200) {
          const User: UserPerfilModel = {
            user: {
              id : Auth._User?.user?.id,
              name: Auth._User?.user?.name,
              username: Auth._User?.user?.username,
              email: email,
              password: undefined,
              phone: Auth._User?.user?.phone,
              birthday: Auth._User?.user?.birthday,
              gender: Auth._User?.user?.gender,
              status: Auth._User?.user?.status,
              verify: Auth._User?.user?.verify,
              perfilData: {
                presentation: Auth._User?.user?.perfilData?.presentation,
                idMediaDataProfile: Auth._User?.user?.perfilData?.idMediaDataProfile,
                idMediaDataCover: Auth._User?.user?.perfilData?.idMediaDataCover
              },
              createDate: Auth._User?.user?.createDate
            },
            isFollow: Auth._User?.isFollow,
            profilePhoto: Auth._User?.profilePhoto,
            coverPhoto: Auth._User?.coverPhoto,
            seguidos: Auth._User?.seguidos,
            seguidores: Auth._User?.seguidores
          };
          Auth.setUser(User);
          Notify(e.data, 'El email')
          setEditItems(0);
        } else {
          Notify(e.data, 'El email')
        }
      })
      .catch((err: any) => {
        Notify(111111, 'El email')
        console.error(err);
      });
  };

  const changePassword = () => {
    if (validarContrasena(NewPassword!)) {
      Changes.Password(ActualPassword!, NewPassword!, Auth._User?.user?.id!)
        .then((e: any) => {
          if (e.status === 200) {
            Notify(e.data, 'La contraseña')
            setActualPassword('')
            setNewPassword('')
            setReplyNewpPassword('')
            setConfigOPtion(0);
          } else {
            Notify(e.data, 'La contraseña')
          }
        })
        .catch((err: any) => {
          Notify(111111, 'La contraseña')
          console.error(err);
        });
    }
  };

  const validarContrasena = (contrasena: string) => {
    if (contrasena.length < 6) {
      setError("La contraseña debe tener al menos 6 caracteres.");
      setErrorIsActive(true);
      return false;
    }

    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!$@%])[0-9a-zA-Z!$@%]+$/;
    if (!regex.test(contrasena)) {
      setError("La contraseña debe incluir números, letras y caracteres especiales (!$@%).");
      setErrorIsActive(true);
      return false;
    }

    setValidateUsername(true);
    setErrorIsActive(false);
    return true;
  }

  const desactiveAccount = () => {
    setModalConfirmation([false, ""]);
    Changes.DesactiveAccount(Auth._User?.user?.id!)
      .then((e: any) => {
        if (e.status === 200) {
          Notify(e.data, 'Tu cuenta')
          setEditItems(0);
          closeConnection();
        } else {
          Notify(e.data, 'Tu cuenta')
        }
      })
      .catch((err: any) => {
        Notify(111111, 'Tu cuenta')
        console.error(err);
      });
  };

  const DeleteAccount = () => {
    setModalConfirmation([false, ""]);
    Changes.DeleteAccount(Auth._User?.user?.id!)
      .then((e: any) => {
        if (e.status === 200) {
          Notify(e.data, 'Tu cuenta')
          setEditItems(0);
          closeConnection();
        } else {
          Notify(e.data, 'Tu cuenta')
        }
      })
      .catch((err: any) => {
        Notify(111111, 'Tu cuenta')
        console.error(err);
      });
  };

  return (
    <>
      <br />
      <div className="col-md-8 config-container">
        <h3>Configuración</h3>
        <div className="config-box">
          <ul>
            <li
              style={{ borderBottom: '3px solid #808080' }}
              onClick={() => {
                setConfigOPtion(0);
                setEditItems(0);
              }}
            >
              General
            </li>
            <li
              onClick={() => {
                setConfigOPtion(1);
                setEditItems(0);
              }}
            >
              Cambiar contraseña
            </li>
            <li onClick={() => window.open(window.location.origin + '/comunity/ayuda', '_blank')}>Ayuda</li>
          </ul>
          <hr />
          <div className="col-md-6 config-model">
            {configOPtion === 0 && (
              <>
                {editItems === 0 && (
                  <>
                    <div className="config_item">
                      <b>Nombre</b>{" "}
                      <label
                        className="label-edit"
                        onClick={() => setEditItems(1)}
                      >
                        editar
                      </label>
                      <div>
                        <span>{name}</span>
                      </div>
                    </div>
                    <div className="config_item">
                      <b>Usuario</b>{" "}
                      <label
                        className="label-edit"
                        onClick={() => setEditItems(2)}
                      >
                        editar
                      </label>
                      <div>
                        <span>{username}</span>
                      </div>
                    </div>
                    <div className="config_item">
                      <b>Presentacion</b>{" "}
                      <label
                        className="label-edit"
                        onClick={() => setEditItems(3)}
                      >
                        editar
                      </label>
                      <div>
                        <span>{presentacion}</span>
                      </div>
                    </div>
                    <div className="config_item">
                      <b>Email</b>{" "}
                      <label
                        className="label-edit"
                        onClick={() => setEditItems(4)}
                      >
                        editar
                      </label>
                      <div>
                        <span>{email}</span>
                      </div>
                    </div>
                    <div className="config_item">
                      <b>Numero</b>{" "}
                      <label
                        className="label-edit"
                        onClick={() => setEditItems(5)}
                      >
                        editar
                      </label>
                      <div>
                        <span>{phone}</span>
                      </div>
                    </div>
                    <div className="config_item">
                      <label
                        className="label-edit"
                        onClick={() =>
                          setModalConfirmation([true, "desactivar"])
                        }
                      >
                        Desactivar cuenta temporalmente
                      </label>
                    </div>

                    <div className="config_item">
                      <label
                        className="label-edit"
                        onClick={() => setModalConfirmation([true, "eliminar"])}
                      >
                        Eliminar cuenta definitivamente
                      </label>
                    </div>
                  </>
                )}

                {editItems === 1 && (
                  <>
                    <h4>Nombre</h4>
                    <span>
                      Usa el nombre por el cual te conozcan asi sera mas facil
                      encontrarte cuando te busquen.
                      <br />
                      Tu nombre debe tener un tamaño maximo de 50 caracteres.
                    </span>
                    <br />
                    <br />
                    <div className="col-sm-10 m-auto mb-1 mt-4">
                      <input
                        className="form-control config-input"
                        type="text"
                        placeholder="Nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        maxLength={50}
                      />
                    </div>
                    <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                      {errorIsActive && (
                        <span className="ErrorLegens">{error}</span>
                      )}
                    </div>
                    <button
                      className="form-control btn btn-primary"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                      onClick={() => {
                        if (name === undefined || name === "") {
                          setError("Debes completar tu nombre");
                          setErrorIsActive(true);
                        } else {
                          if (name === Auth._User?.user?.name) {
                            setEditItems(0);
                          } else {
                            changName();
                            setErrorIsActive(false);
                          }
                        }
                      }}
                    >
                      Guardar
                    </button>
                    <div className="back-label">
                      <span
                        onClick={() => {
                          setEditItems(0);
                          setName(Auth._User?.user?.name!);
                        }}
                      >
                        Atras
                      </span>
                    </div>
                  </>
                )}

                {editItems === 2 && (
                  <>
                    <h4>Usuario</h4>
                    <span>
                      Tu nombre de usuario es otra de las formas en que las
                      personas pueden buscarte ademas de que es una llave unica
                      de acceso a tu cuenta.
                      <br />
                      Tu nombre de usuario debe tener un tamaño maximo de 30
                      caracteres y no se aceptan espacios ni caracteres
                      especiales.
                    </span>
                    <br />
                    <br />
                    <div className="col-sm-10 m-auto InputDiv">
                      <input
                        className="form-control config-input"
                        type="text"
                        placeholder="Usuario"
                        onChange={(e) => {
                          if (/\s/.test(e.target.value)) {
                            setError("El usuario no puede contener espacios");
                            setErrorIsActive(true);
                          } else {
                            setUsername(e.target.value);
                          }
                        }}
                        onBlur={VerifyUsername}
                        value={username}
                        maxLength={30}
                      />
                      <FontAwesomeIcon
                        className="IconValidation"
                        style={{
                          color: validateUsername ? "green" : "#e75e69",
                        }}
                        icon={faCheckCircle}
                      />
                    </div>
                    <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                      {errorIsActive && (
                        <span className="ErrorLegens">{error}</span>
                      )}
                    </div>
                    <button
                      className="form-control btn btn-primary"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                      onClick={() => {
                        if (username === undefined || username === "") {
                          setError("Debes completar tu usuario");
                          setErrorIsActive(true);
                        } else {
                          VerifyUsername();

                          if (validateUsername) {
                            changeUsername();
                            setEditItems(0);
                            setErrorIsActive(false);
                          }
                        }
                      }}
                    >
                      Guardar
                    </button>
                    <div className="back-label">
                      <span
                        onClick={() => {
                          setEditItems(0);
                          setUsername(Auth._User?.user?.username!);
                        }}
                      >
                        Atras
                      </span>
                    </div>
                  </>
                )}

                {editItems === 3 && (
                  <>
                    <h4>Presentacion</h4>
                    <span>
                      La presentacion es una pequeña descripcion de ti, podes
                      poner alli lo que tu quieras.
                      <br />
                      Tu presentacion debe tener un tamaño maximo de 200
                      caracteres.
                    </span>
                    <br />
                    <br />
                    <div className="col-sm-10 m-auto InputDiv">
                      <textarea
                        cols={30}
                        rows={10}
                        className="form-control"
                        placeholder="Presentacion"
                        onChange={(e) => {
                          setPresentation(e.target.value);
                        }}
                        value={presentacion}
                        maxLength={200}
                      ></textarea>
                    </div>
                    <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                      {errorIsActive && (
                        <span className="ErrorLegens">{error}</span>
                      )}
                    </div>
                    <button
                      className="form-control btn btn-primary"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                      onClick={() => {
                        if (presentacion === undefined || presentacion === "") {
                          setError("Debes completar tu presentacion");
                          setErrorIsActive(true);
                        } else {
                          changePresentation();
                          setEditItems(0);
                          setErrorIsActive(false);
                        }
                      }}
                    >
                      Guardar
                    </button>
                    <div className="back-label">
                      <span
                        onClick={() => {
                          setEditItems(0);
                          setPresentation(Auth._User?.user?.perfilData?.presentation!);
                        }}
                      >
                        Atras
                      </span>
                    </div>
                  </>
                )}

                {editItems === 4 && (
                  <>
                    <div className="col-sm-10 m-auto InputDiv">
                      <label>Email</label>
                      <input
                        className="form-control config-input"
                        type="email"
                        placeholder="Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        onBlur={ValidateEmail}
                        value={email}
                      />
                      <FontAwesomeIcon
                        className="IconValidation"
                        style={{ color: validateEmail ? "green" : "#e75e69" }}
                        icon={faCheckCircle}
                      />
                    </div>
                    <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                      {errorIsActive && (
                        <span className="ErrorLegens">{error}</span>
                      )}
                    </div>
                    <button
                      className="form-control btn btn-primary"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                      onClick={() => {
                        if (email === undefined || email === "") {
                          setError("Debes completar tu email");
                          setErrorIsActive(true);
                          setValidateEmail(false);
                        } else {
                          if (
                            /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
                              email
                            )
                          ) {
                            ValidateEmail();
                            if (validateEmail) {
                              setEditItems(0);
                              setErrorIsActive(false);
                              changeEmail();
                            }
                          } else {
                            setError("Email no valido ");
                            setErrorIsActive(true);
                            setValidateEmail(false);
                          }
                        }
                      }}
                    >
                      Guardar
                    </button>
                    <div className="back-label">
                      <span
                        onClick={() => {
                          setEditItems(0);
                          setEmail(Auth._User?.user?.email!);
                        }}
                      >
                        Atras
                      </span>
                    </div>
                  </>
                )}

                {editItems === 5 && (
                  <>
                    <div className="col-sm-10 m-auto InputDiv">
                      <label>Telefono</label>
                      <input
                        className="form-control config-input"
                        type="number"
                        placeholder="Telefono"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        value={phone}
                      />
                      <FontAwesomeIcon
                        className="IconValidation"
                        icon={faCheckCircle}
                      />
                    </div>
                    <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                      {errorIsActive && (
                        <span className="ErrorLegens">{error}</span>
                      )}
                    </div>
                    <button
                      className="form-control btn btn-primary"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                      onClick={() => {
                        changePhone();

                        setErrorIsActive(false);
                      }}
                    >
                      Guardar
                    </button>
                    <div className="back-label">
                      <span
                        onClick={() => {
                          setEditItems(0);
                          setPhone(Auth._User?.user?.phone);
                        }}
                      >
                        Atras
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
            {configOPtion === 1 && (
              <>
                {editItems === 0 && (
                  <>
                    <div className="col-sm-10 m-auto mb-1">

                      <h4>Cambiar contraseña</h4>
                      <span>
                        La contraseña debe tener al menos 6 caracteres e incluir una combinación de números,
                        letras y caracteres especiales (!$@%).
                      </span>
                      <br />
                      <br />
                    </div>
                    <div className="pass-input-div col-sm-10 m-auto mb-1">
                      <input
                        className="form-control pass-input"
                        type={VisibleActualPassword ? 'text' : 'password'}
                        placeholder="Actual Contraseña"
                        onChange={(e) => setActualPassword(e.target.value)}
                        value={ActualPassword}
                      />
                      {!VisibleActualPassword
                        ? <FontAwesomeIcon
                          className="icon-view-pass"
                          icon={faEye} onClick={() => toggleVisibleActualPassword()} />
                        : <FontAwesomeIcon
                          className="icon-view-pass"
                          icon={faEyeSlash} onClick={() => toggleVisibleActualPassword()} />
                      }

                    </div>
                    <div className="pass-input-div col-sm-10 m-auto mb-1">
                      <input
                        className="form-control pass-input"
                        type={VisibleNewPassword ? 'text' : 'password'}
                        placeholder="Nueva contraseña"
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={NewPassword}
                      />
                      {!VisibleNewPassword
                        ? <FontAwesomeIcon
                          className="icon-view-pass"
                          icon={faEye} onClick={() => toggleVisibleNewPassword()} />
                        : <FontAwesomeIcon
                          className="icon-view-pass"
                          icon={faEyeSlash} onClick={() => toggleVisibleNewPassword()} />
                      }
                    </div>
                    <div className="pass-input-div col-sm-10 m-auto mb-1">
                      <input
                        className="form-control pass-input"
                        type={VisibleReplyNewpPassword ? 'text' : 'password'}
                        placeholder="Repetir nueva contraseña"
                        onChange={(e) => setReplyNewpPassword(e.target.value)}
                        value={ReplyNewpPassword}
                      />
                      {!VisibleReplyNewpPassword
                        ? <FontAwesomeIcon
                          className="icon-view-pass"
                          icon={faEye} onClick={() => toggleVisibleReplyNewpPassword()} />
                        : <FontAwesomeIcon
                          className="icon-view-pass"
                          icon={faEyeSlash} onClick={() => toggleVisibleReplyNewpPassword()} />
                      }
                    </div>
                    <div className="col-sm-10 m-auto mb-1 ErrorLegensDiv">
                      {errorIsActive && (
                        <span className="ErrorLegens">{error}</span>
                      )}
                    </div>
                    <button
                      className="form-control btn btn-primary"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                      onClick={() => {

                        if (NewPassword === undefined || NewPassword === "") {
                          setError("Debes completar tu contraseña");
                          setErrorIsActive(true);
                        } else if (NewPassword != ReplyNewpPassword) {
                          setError("La contraseña nueva no coincide");
                          setErrorIsActive(true);

                        } else {
                          setErrorIsActive(false);
                          changePassword();
                        }
                      }}
                    >
                      Guardar
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {modalConfirmation[0] && (
        <div className="OPmodal_box">
          <div className="OPmodal_container">
            <div className="OPmodal_button">
              {modalConfirmation[1] === "desactivar" && (
                <>
                  <div style={{ width: "50px", margin: "auto" }}>
                    <FontAwesomeIcon
                      style={{ width: "50px", height: "50px", color: "orange" }}
                      icon={faExclamationCircle}
                    />
                  </div>
                  <div>
                    <span>
                      Esta cuenta quedara inactiva hasta que vuelvas a iniciar
                      session. Los usuarios no podran encontrarte ni ver tu
                      contenido. Para activar tu cuenta nuevamente debes iniciar
                      sesion. Estas seguro que deseas desactivar tu cuenta?
                    </span>
                  </div>
                  <div className="OptionsList">
                    <div
                      className="OPoption"
                      onClick={() => desactiveAccount()}
                    >
                      <b id="Eliminar">Confirmar</b>
                    </div>
                  </div>
                </>
              )}
              {modalConfirmation[1] === "activar" && (
                <>
                  <div style={{ width: "50px", margin: "auto" }}>
                    <FontAwesomeIcon
                      style={{ width: "50px", height: "50px", color: "red" }}
                      icon={faExclamationCircle}
                    />
                  </div>
                  <div>
                    <span>
                      Esta cuenta quedara eliminada definitivamente. Los
                      usuarios no podran encontrarte ni ver tu contenido. Estas
                      seguro que deseas eliminar tu cuenta?
                    </span>
                  </div>
                  <div className="OptionsList">
                    <div className="OPoption" onClick={() => DeleteAccount()}>
                      <b id="Eliminar">Confirmar</b>
                    </div>
                  </div>
                </>
              )}

              {modalConfirmation[1] === "eliminar" && (
                <>
                  <div style={{ width: "50px", margin: "auto" }}>
                    <FontAwesomeIcon
                      style={{ width: "50px", height: "50px", color: "red" }}
                      icon={faExclamationCircle}
                    />
                  </div>
                  <div>
                    <span>
                      Esta cuenta quedara eliminada definitivamente. Los
                      usuarios no podran encontrarte ni ver tu contenido. Estas
                      seguro que deseas eliminar tu cuenta?
                    </span>
                  </div>
                  <div className="OptionsList">
                    <div className="OPoption" onClick={() => DeleteAccount()}>
                      <b id="Eliminar">Confirmar</b>
                    </div>
                  </div>
                </>
              )}
              <div className="OptionsList">
                <div
                  className="OPoption"
                  onClick={() => {
                    setModalConfirmation([false, ""]);
                  }}
                >
                  <b>Cancelar</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <FooterBlog />
    </>
  );
};

export default Config;
