import React from 'react'
import { Route } from 'react-router-dom'
import AppLayout from '../Layout/AppLayout'
import Nav from '../Modules/Nav/Nav'
import { Explorer, Home, Login, Perfil, Settings, SignUp } from '../Pages'
import ComunityRoutes from './ComunityRoutes'
import CustomRoutes from './CustomRoutes'
import InboxRoutes from './InboxRoutes'
import { PrivateRoute } from './PrivateRoute'

const DashboardRoutes = () => {
    return (
        <CustomRoutes>
            <Route element={<PrivateRoute />}>
                <Route element={<AppLayout/>}>
                    <Route index element={<Home/>} />
                    <Route path="/:username" element={<Perfil/>} />
                    <Route path="/explorer" element={<Explorer />}/>
                    <Route path="/settings" element={<Settings/>} />
                    <Route path="/inbox/*" element={<InboxRoutes/>} />
                </Route>
            </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/comunity/*" element={<ComunityRoutes />} />
        </CustomRoutes>
    )
}

export default DashboardRoutes