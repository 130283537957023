import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useState } from "react";
import { MessageParams } from "../Models/Message/MessageParams";
import Auth from "../Services/Auth/AuthService";
import { useNavigate } from "react-router-dom";

export const useHubs = () => {
  const navigate = useNavigate();
  const [connection, setConnection] = useState<any>();
  const [messages, setMessages] = useState<MessageParams[]>([]);
  const [usersConnecting, setUsers] = useState<any>([]);
  const [lastMessageState, setLastMessageState] = useState<MessageParams>();

  const joinRoom = async (Id: string, User: string) => {
    try {
      const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_CHAT_URL}`)
        .configureLogging(LogLevel.Information)
        .build();
      console.log(process.env.REACT_APP_CHAT_URL);

      connection.on("UsersInRoom", (users: any) => {
        setUsers(users);
      });

      connection.on("ReceiveMessage", (message: any) => {
        setMessages((messages: any) => [...messages, message]);
        setLastMessageState(message);
      });

      connection.onclose((e: any) => {
        setConnection("");
        setMessages([]);
        setUsers([]);
      });

      await connection.start();
      await connection.invoke("JoinRoom", { Id, User });
      setConnection(connection);
    } catch (e) {
      console.log(e);
    }
  };

  const closeConnection = async () => {
    try {
      await connection.stop();
      Auth.removeTokenStorage();
      navigate(`/login`, { replace: true });
    } catch (e) {
      console.log(e);
    }
  };

  const sendMessage = async (message: MessageParams) => {
    try {
      if (connection.state === "Connected") {
        connection
          .invoke("SendMessage", message)
          .then(() => console.log("Mensaje enviado correctamente"))
          .catch((err: any) => console.error(err.toString()));
      } else {
        console.error("La conexión no está lista");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setMessages_F = (m: any) => {
    setMessages(m);
  };
  return {
    joinRoom,
    usersConnecting,
    messages,
    sendMessage,
    lastMessageState,
    setMessages_F,
    closeConnection,
  };
};
