import React, { useState } from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import { MessageParams } from '../../../../../Models/Message/MessageParams';
import { MessageSaveParams } from '../../../../../Models/Message/MessageSaveParams';
import { UserPerfilModel } from '../../../../../Models/User/UserPerfilModel';
import Auth from '../../../../../Services/Auth/AuthService';
import Chat_S from '../../../../../Services/Chat/ChatService';
import Data from '../../../../../Services/Data/Data';
import './SendMessageForm.css'

interface ISendMessageForm {
  userData: UserPerfilModel | undefined;
  sendMessage: Function;
  idChat: string | undefined;
}

const SendMessageForm = (props: ISendMessageForm) => {
  const [message, setMessage] = useState<string>("");

  const saveMessge = (m: MessageSaveParams) => {
    Chat_S.Save(m)
      .then((res: any) => {
      })
      .catch((e: any) => {
        console.log(e);
      })
  }

  const handleKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      SendMessage();
    }
  }
  

  const SendMessage = () => {
    const time = Date.now();
    const today = new Date(time);
    
    if (props.idChat) {
      const messageObj: MessageParams = {
        id: props.idChat,
        idUserReceiver: props.userData?.user?.id,
        idMediaDataProfileReceiver: props.userData?.user?.perfilData?.idMediaDataProfile,
        idUserSender: Auth._User?.user?.id!,
        idMediaDataProfileSender: Auth._User?.user?.perfilData?.idMediaDataProfile,
        messageValue: message,
        usernameReceiver: props.userData?.user?.username,
        
        usernameSender: Auth._User?.user?.username!,

        isDirect: true,
        nameSender: Auth._User?.user?.name,
        nameReceiver: props.userData?.user?.name,
        nameGroup: '',
        createDate: `${Data._Today}`

      }
      const messageSaveObj: MessageSaveParams = {
        idChat: props.idChat,
        idUser: Auth._User?.user?.id!,
        messageValue: message
      }
      saveMessge(messageSaveObj);
      props.sendMessage(messageObj);
      setMessage('');
    }
  }

  return (
    <div>
      <input type='text'
        className='messageInput'
        id='inputMessageValue'
        placeholder='message...'
        onChange={e => setMessage(e.target.value)}
        value={message}
        disabled={!props.idChat}
        onKeyPress={handleKeyPress}
        autoComplete="off"
      />
      <button className='btn btn-primary buttonsendmessage'
        onClick={SendMessage} disabled={!message}>Send</button>
    </div>
  )
}

export default SendMessageForm