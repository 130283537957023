import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PostOptions.css'
import React, { useContext, useState } from 'react'
import { PostModel } from '../../Models/Post/PostModel';
import Status from '../../Services/Status/StatusService';
import History from '../../Services/History/HistoryService';
import { AppContext } from '../../context/AppContext';
import EditModal from './EditModal';
import { PostParams } from '../../Models/Post/PostParams';
import Auth from '../../Services/Auth/AuthService';
import Post from '../../Services/Post/PostService';

interface IPostOPtions {
    setViewOP: Function
    Data: any
    FilterData: Function
    whereCode: boolean
    setHyRunnig?: Function
    handlePostChange?: Function
}

const PostOptions = ({ setViewOP, Data, FilterData, whereCode, setHyRunnig, handlePostChange }: IPostOPtions) => {

    const [EditedMOdal, setEditedMOdal] = useState<boolean>(true)
    const Context = useContext(AppContext);

    if (!Context) {
        return null;
    }
    const { perfilPost, setPerfilPost } = Context;

    const setmypost = (p: any) => {
        p.status = 9;
        const addpost = [p, ...perfilPost];
        const newpost = addpost.sort((a, b) => {
            const fechaA = new Date(a.date!);
            const fechaB = new Date(b.date!);

            if (fechaA > fechaB) {
                return -1;
            } else if (fechaA < fechaB) {
                return 1;
            } else {
                return 0;
            }
        });
        setPerfilPost(newpost)
    }

    const DeletePost = () => {
        Status.ApplyStatusToDelete(Data?.id)
            .then((e: any) => {
                FilterData(Data?.id)
                setViewOP(false)
            })
            .catch((err: any) => {
                console.error(err);
            })
    }

    const ArchivedPost = () => {
        Status.ApplyStatusToArchived(Data?.id)
            .then((e: any) => {
                FilterData(Data?.id)
                setViewOP(false)
            })
            .catch((err: any) => {
                console.error(err);
            })
    }


    const DesArchivedPost = () => {
        Status.ApplyStatusToActive(Data?.id)
            .then((e: any) => {
                FilterData(Data?.id)
                setmypost(Data)
                setViewOP(false)
            })
            .catch((err: any) => {
                console.error(err);
            })
    }

    const DeleteMyHistory = () => {
        History.Delete(Data?.id)
            .then((e: any) => {
                FilterData(Data?.id!)
                setViewOP(false)
            })
            .catch((err: any) => {
                console.error(err);
            })
    }

    const Update = (text: string) => {
        // const post: PostParams = {
        //     id: Data?.id,
        //     idUser: Auth._User?.user?.id,
        //     photo: '',
        //     description: text,
        // }
        // const model: PostModel = {
        //     id: Data?.id,
        //     idUser: Data?.idUser,
        //     idMediaData: Data?.idMediaData
        //     photo: "",
        //     description: text,
        //     status: Data?.status,
        //     CreateDate: Data?.date,
        //     username: Data?.username,
        //     profilePhoto: Data?.profilePhoto,
        //     likes: Data?.likes,
        //     comments: Data?.comments,
        //     liked: Data?.liked
        // }
        // Post.Update(post)
        //     .then((e: any) => {
        //         if (handlePostChange) {
        //             handlePostChange(model)
        //         }
        //     })
        //     .catch((err: any) => {
        //         console.log(err);
        //     })
    }

    return (
        <>
            {EditedMOdal
                ?
                (<div className="OPmodal_box">
                    <div className="OPmodal_container">
                        <div className="OPmodal_button">
                            <div
                                onClick={() => {
                                    if (setHyRunnig) {
                                        setHyRunnig(true)
                                    }
                                    setViewOP(false)
                                }}
                                className='closeBox'>
                                <FontAwesomeIcon icon={faX} />
                            </div>
                            {whereCode
                                ? (<div className="OptionsList">
                                    <div className='OPoption' onClick={DeletePost}>
                                        <b id='Eliminar'>Eliminar</b>
                                    </div>
                                    <div className='OPoption' onClick={() => setEditedMOdal(false)}>
                                        <b>Editar</b>
                                    </div>
                                    {console.log(Data.status == 9)}
                                    {Data.status == 9 || Data.status == 14
                                        ? (
                                            <div className='OPoption' onClick={ArchivedPost}>
                                                <b>Archivar</b>
                                            </div>
                                        )
                                        : Data.status == 12 && (
                                            <div className='OPoption' onClick={DesArchivedPost}>
                                                <b>Desarchivar</b>
                                            </div>
                                        )
                                    }

                                    <div className='OPoption' onClick={() => setViewOP(false)}>
                                        <b>Cancelar</b>
                                    </div>
                                </div>)
                                : (<div className="OptionsList">
                                    <div className='OPoption' onClick={DeleteMyHistory}>
                                        <b id='Eliminar'>Eliminar</b>
                                    </div>
                                    <div className='OPoption' onClick={() => { if (setHyRunnig) { setHyRunnig(true) } setViewOP(false) }}>
                                        <b>Cancelar</b>
                                    </div>
                                </div>)}

                        </div>
                    </div>
                </div >)
                :
                (<EditModal closeModal={setViewOP} text={Data.description} saveFunction={Update} />)
            }
        </>
    )
}

export default PostOptions