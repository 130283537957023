import React, { useContext, useEffect, useState } from 'react';
import './RoundProfileCard.css';
import photoSrc from "../../../../assets/img/UserProfile.jpg";
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../context/AppContext';
import { ImageModel } from '../../../../Models/Post/ImageModel';
import MediaData from '../../../../Services/MediaData/MediaDataService';

interface IRoundProfileCard {
  name: string
  username: string
  idMediaDataProfile: string
}

const RoundProfileCard = ({ name, username, idMediaDataProfile }: any) => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState<ImageModel>()

  useEffect(() => {
    if (idMediaDataProfile) {
      handleSrc(idMediaDataProfile)
    }
  }, [idMediaDataProfile])

  const Context = useContext(AppContext);
  if (!Context) {
    return null;
  }
  const { searchImage, handleImageBank } = Context;

  const handleSrc = (id: string) => {
    console.log(id);
    
    const image = searchImage(id)
    if (image.length > 0) {
      setProfileData(image[0])
    }
    else {
      MediaData.GetById(id)
        .then((e: any) => {
          setProfileData(e.data)
          handleImageBank(e.data)
        })
        .catch((err: any) => {
          console.error(err)
        })

    }
  }
  return (
    <div className="round-profile-card col-md-3" onClick={() => { navigate(`/${username}`, { replace: true }) }}>
      <div className="card-content">
        <img src={profileData ? profileData.value : photoSrc} alt={name} className="profile-photo" />
      </div>
      <div className="text-info">
        <h3 className="name">{name}</h3>
        <p className="username">@{username}</p>
      </div>
    </div>
  );
};

export default RoundProfileCard;
