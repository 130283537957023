import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../context/AppContext'
import { ImageModel } from '../../../../Models/Post/ImageModel'
import { PostModel } from '../../../../Models/Post/PostModel'
import Post from '../../../../Services/Post/PostService'
import MediaData from '../../../../Services/MediaData/MediaDataService'

interface IPerfilPublicationImage {
    scrollToElement: Function
    index: number
    publication: PostModel
}

const PerfilPublicationImage = ({ scrollToElement, index, publication }: IPerfilPublicationImage) => {
    const [image, setImage] = useState<ImageModel>()

    useEffect(() => {
        handleSrc(publication.idMediaData!)
    }, [publication.id])

    const handleSrc = (id: string) => {
        const image = searchImage(id)
        if (image.length > 0) {
            setImage(image[0])
        }
        else {
            MediaData.GetById(id)
                .then((e: any) => {
                    setImage(e.data)
                    handleImageBank(e.data)
                })
                .catch((err: any) => {
                    console.error(err)
                })

        }
    }

    const Context = useContext(AppContext);
    if (!Context) {
        return null;
    }
    const { searchImage, handleImageBank } = Context;
    
    return (
        <img
            onClick={() => scrollToElement(index)}
            key={publication.id}
            style={{ cursor: 'pointer' }}
            className='PhotoPost'
            src={image?.value}
            alt="" />
    )
}

export default PerfilPublicationImage