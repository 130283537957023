import { AppContext } from '../context/AppContext';
import Nav from '../Modules/Nav/Nav'
import Space from "../Modules/Nav/SpaceBehindNav";
import SearchBox from '../Modules/SearchBox/SearchBox';
import { Outlet } from 'react-router-dom';
import { useIndexData } from '../hooks/useIndexData';
import { useUserHook } from '../hooks/useUserHook';
import { useHubs } from '../hooks/useHubs';
import Auth from '../Services/Auth/AuthService';
import { useEffect, useState } from 'react';
import { useExplorerHook } from '../hooks/useExplorerHook';
import { useImageBankHook } from '../hooks/useImageBankHook';

const AppLayout = () => {
    const [onlinePass, setOnlinePass] = useState<boolean>(false)

    const { searchVisible,
        buttonsVisible,
        setButtonsVisible_F,
        historyList,
        setHistoryList_F,
        myhistorys,
        ConcatMyHistorys,
        setMyHistorys_F,
        setPhoto,
        setOnLoadPhoto,
        setFooterViewer_F,
        footerViewer,
        BdPost,
        OnLoadPost,
        setBdPost_F,
        setButtonsVisible,
        setSearchVisible,
        searchInput,
        setSearchInput,
        setUsersSearch,
        chargingPost,
        usersSearch,
        chargingMyHistory,
        chargingOthersHistorys,
        goToExplorerFromIndex
    } = useIndexData();

    const {
        perfilPost,
        setPerfilPost,
        perfiluser,
        getPerfilPost,
        getPerfilDta,
        cantOfFollow,
        setCantOfFollow,
        cantOfFollowings,
        setCantOfFollowings,
        isActiveSpinner,
        isPostCharging,
        isfollow,
        setIsFollow,
    } = useUserHook();


    const {
        tendenciasCharger,
        tendencias,
        mosaicosCharger,
        mosaicos,
        users
    } = useExplorerHook();

    const {
        searchImage,
        handleImageBank
    } = useImageBankHook();

    const { joinRoom, usersConnecting, messages, sendMessage, lastMessageState, setMessages_F, closeConnection } = useHubs();

    useEffect(() => {
        if (!onlinePass) {
            joinRoom(Auth._User?.user?.id!, Auth._User?.user?.username!)
            setOnlinePass(true)
        }
    }, [joinRoom, onlinePass])

    return (
        <AppContext.Provider value={{
            searchVisible,
            buttonsVisible,
            setButtonsVisible_F,
            historyList,
            setHistoryList_F,
            myhistorys,
            ConcatMyHistorys,
            setMyHistorys_F,
            setPhoto,
            setOnLoadPhoto,
            setFooterViewer_F,
            footerViewer,
            BdPost,
            OnLoadPost,
            setBdPost_F,
            usersConnecting,
            messages,
            sendMessage,
            lastMessageState,
            setMessages_F,
            closeConnection,
            perfilPost,
            setPerfilPost,
            perfiluser,
            getPerfilPost,
            getPerfilDta,
            cantOfFollow,
            setCantOfFollow,
            cantOfFollowings,
            setCantOfFollowings,
            isActiveSpinner,
            isPostCharging,
            isfollow,
            setIsFollow,
            chargingPost,
            chargingMyHistory,
            chargingOthersHistorys,
            goToExplorerFromIndex,
            tendenciasCharger,
            tendencias,
            mosaicosCharger,
            mosaicos,
            users,
            searchImage,
            handleImageBank
        }}
        >
            <div className='MainContainer'
                onClick={(e: any) => {
                    if (buttonsVisible) {
                        setButtonsVisible(!buttonsVisible);
                    }
                    if (searchVisible) {
                        setSearchVisible(!searchVisible);
                    }
                    if (e.target.name !== "SearchInput") {
                        if (searchInput) {
                            setSearchInput(!searchInput);
                        }
                    }
                }}
            >
                <Nav
                    searchVisible={searchVisible}
                    setSearchVisible={setSearchVisible}
                    setUsersSearch={setUsersSearch}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    closeConnection={closeConnection}
                />
                <Space />
                <Outlet />
                {searchVisible && (<SearchBox usersSearch={usersSearch} searchload={searchVisible} />)}
            </div>
        </AppContext.Provider>
    )
}

export default AppLayout