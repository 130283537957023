import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faEllipsisVertical,
  faEye,
  faHeart,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { HistoryListItemModel } from "../../../Models/History/HistoryListItemModel";
import History from "../../../Services/History/HistoryService";
import UserProfile from "../../../assets/img/UserProfile.jpg";
import "./HistoryViewer.css";
import ChargerSlice from "../../ChargerSlice/ChargerSlice";
import HistoryProgressBar from "./HistoryProgressBar/HistoryProgressBar";
import { HistoryModel } from "../../../Models/History/HistoryModel";
import { useNavigate } from "react-router-dom";
import Auth from "../../../Services/Auth/AuthService";
import Like from "../../../Services/Like/LikeService";
import PostOptions from "../../PostOptions/IndexPostOptions";
import { HistoryUserViewer } from "../../../Models/History/HistoryUserViewer";
import MediaData from "../../../Services/MediaData/MediaDataService";
import { AppContext } from "../../../context/AppContext";

interface IHistoryViewer {
  Data: HistoryListItemModel[];
  indexUser: number;
  indexHistory: number;
  setViewHistoryBox: Function;
  setHistorys: Function;
  // myHistoryLength?: number
  // setMyHistoryLength?: Function
}

const HistoryViewer = (props: IHistoryViewer) => {
  const navigate = useNavigate();
  const [indexUser, setIndexUser] = useState<number>(props.indexUser!);
  const [indexHistory, setIndexHistory] = useState<number>(props.indexHistory!);
  const [running, setRunning] = useState<boolean>(true);
  const [watchModalOP, setWatchModalOP] = useState<boolean>(false);
  const [historyUserList, setHistoryUserList] = useState<boolean>(false);
  const [UsersViewers, setUsersViewers] = useState<HistoryUserViewer[]>();

  useEffect(() => {
    if (props.Data[indexUser].historysData![indexHistory] != undefined) {
      if (
        props.Data[indexUser].historysData![indexHistory].idMediaData &&
        props.Data[indexUser].historysData![indexHistory].mediaData == null
      ) {
        getHistory(
          props.Data[indexUser].historysData![indexHistory].idMediaData!
        );
      }
      if (
        props.Data[indexUser].idMediaDataProfile &&
        props.Data[indexUser].profilePhoto == null
      ) {
        getProfile(props.Data[indexUser].idMediaDataProfile!);
      }
    } else {
      props.setViewHistoryBox(false);
    }
  }, [props.Data, indexHistory, indexUser]);

  useEffect(() => {
    if (props.Data[indexUser].historysData![indexHistory] != undefined) {
      if (
        props.Data[indexUser].historysData![indexHistory].mediaData &&
        !props.Data[indexUser].historysData![indexHistory].seen &&
        props.Data[indexUser].historysData![indexHistory].idUser !==
          Auth._User?.user?.id
      ) {
        SetView(
          props.Data[indexUser].historysData![indexHistory].id!,
          Auth._User?.user?.id!
        );
      }
    } else {
      props.setViewHistoryBox(false);
    }
  }, [props.Data]);

  const Context = useContext(AppContext);
  if (!Context) {
    return null;
  }
  const { searchImage, handleImageBank } = Context;

  const SetView = (idhistory: string, iduser: string) => {
    setFillHistory(
      props.Data[indexUser].profilePhoto!,
      props.Data[indexUser].historysData![indexHistory].mediaData!,
      props.Data[indexUser].historysData![indexHistory].id!,
      props.Data[indexUser].historysData![indexHistory].amountOfLikes!,
      props.Data[indexUser].historysData![indexHistory].liked!,
      true
    );
    History.SetViewHistory(idhistory, iduser)
      .then((e: any) => {})
      .catch((err: any) => {
        console.error(err);
      });
  };

  const NextHistory = () => {
    if (props.Data[indexUser].historysData?.length! > indexHistory + 1) {
      setIndexHistory(indexHistory + 1);
    } else {
      if (props.Data?.length! > indexUser + 1) {
        setIndexUser(indexUser + 1);
        setIndexHistory(
          props.Data[indexUser + 1].historysData?.indexOf(
            props.Data[indexUser + 1].historysData?.find(
              (h: HistoryModel) => h.seen === false
            )!
          ) !== -1
            ? props.Data[indexUser + 1].historysData?.indexOf(
                props.Data[indexUser + 1].historysData?.find(
                  (h: HistoryModel) => h.seen === false
                )!
              )!
            : 0
        );
      } else {
        props.setViewHistoryBox(false);
      }
    }
  };

  const getHistory = (id: string) => {
    const image = searchImage(id);
    if (image.length > 0) {
      setFillHistory(
        props.Data[indexUser].profilePhoto!,
        image[0].value!,
        props.Data[indexUser].historysData![indexHistory].id!,
        props.Data[indexUser].historysData![indexHistory].amountOfLikes!,
        props.Data[indexUser].historysData![indexHistory].liked!,
        props.Data[indexUser].historysData![indexHistory].seen!
      );
    } else {
      MediaData.GetById(id)
        .then((e: any) => {
          setFillHistory(
            props.Data[indexUser].profilePhoto!,
            e.data.value,
            props.Data[indexUser].historysData![indexHistory].id!,
            props.Data[indexUser].historysData![indexHistory].amountOfLikes!,
            props.Data[indexUser].historysData![indexHistory].liked!,
            props.Data[indexUser].historysData![indexHistory].seen!
          );
          handleImageBank(e.data);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  const getProfile = (id: string) => {
    const image = searchImage(id);
    if (image.length > 0) {
      setFillHistory(
        image[0].value!,
        props.Data[indexUser].historysData![indexHistory].mediaData!,
        props.Data[indexUser].historysData![indexHistory].id!,
        props.Data[indexUser].historysData![indexHistory].amountOfLikes!,
        props.Data[indexUser].historysData![indexHistory].liked!,
        props.Data[indexUser].historysData![indexHistory].seen!
      );
    } else {
      MediaData.GetById(id)
        .then((e: any) => {
          setFillHistory(
            e.data.value,
            props.Data[indexUser].historysData![indexHistory].mediaData!,
            props.Data[indexUser].historysData![indexHistory].id!,
            props.Data[indexUser].historysData![indexHistory].amountOfLikes!,
            props.Data[indexUser].historysData![indexHistory].liked!,
            props.Data[indexUser].historysData![indexHistory].seen!
          );
          handleImageBank(e.data);
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  const setFillHistory = (
    Profile: string,
    Pht: string,
    id: string,
    amountOfLikes: number,
    liked: boolean,
    seen: boolean
  ) => {
    const historyArray = props.Data[indexUser].historysData!.map(
      (h: HistoryModel) => {
        if (h.id === id) {
          h.mediaData = Pht;
          h.liked = liked;
          h.amountOfLikes = amountOfLikes;
          h.seen = seen;
        }
        return h;
      }
    );

    const historyUser = props.Data.map((D: HistoryListItemModel) => {
      if (D.idUser === historyArray[indexHistory].idUser) {
        D.historysData = historyArray;
        D.profilePhoto = Profile;
      }
      return D;
    });

    props.setHistorys(historyUser);
  };

  const FilterMyHistorys = (id: string) => {
    const historyArray = props.Data[indexUser].historysData!.filter(
      (h: HistoryModel) => h.id != id
    );
    const historyUser = props.Data.map((D: HistoryListItemModel) => {
      D.historysData = historyArray;
      return D;
    });
    props.setHistorys(historyUser);
  };

  const ActionLike = (iduser: string, idhistory: string) => {
    props.Data[indexUser].historysData![indexHistory].liked!
      ? Like.setHistoryLike(iduser, idhistory)
      : Like.RemoveHistoryLike(iduser, idhistory);
  };

  const GetUsersViewers = (id: string) => {
    History.GetUserHistorysViewers(id, 1)
      .then((e: any) => {
        setUsersViewers(e.data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  return (
    <>
      {props.Data[indexUser].historysData![indexHistory] !== undefined && (
        <div className="HYmodal_box">
          <div className="HYmodal_container">
            <div className="HYmodal_button">
              <div className="div_view_container">
                <div className="chevron_box">
                  {indexHistory !== 0 ? (
                    <div
                      onClick={() => {
                        if (indexHistory > 0) {
                          setIndexHistory(indexHistory - 1);
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        className="arrow_left_box"
                        icon={faChevronLeft}
                      />
                    </div>
                  ) : indexUser !== 0 ? (
                    <div
                      onClick={() => {
                        setIndexUser(indexUser - 1);
                        setIndexHistory(
                          props.Data[indexUser - 1].historysData?.indexOf(
                            props.Data[indexUser - 1].historysData?.find(
                              (h: HistoryModel) => h.seen === false
                            )!
                          ) !== -1
                            ? props.Data[indexUser - 1].historysData?.indexOf(
                                props.Data[indexUser - 1].historysData?.find(
                                  (h: HistoryModel) => h.seen === false
                                )!
                              )!
                            : 0
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        className="arrow_left_box"
                        icon={faChevronLeft}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="ViewModalHY">
                  <div className="usersHyContainert">
                    {indexUser > 1 && (
                      <div
                        className="userHy"
                        onClick={() => {
                          setIndexUser(indexUser - 2);
                          setIndexHistory(
                            props.Data[indexUser - 2].historysData?.indexOf(
                              props.Data[indexUser - 2].historysData?.find(
                                (h: HistoryModel) => h.seen === false
                              )!
                            ) !== -1
                              ? props.Data[indexUser - 2].historysData?.indexOf(
                                  props.Data[indexUser - 2].historysData?.find(
                                    (h: HistoryModel) => h.seen === false
                                  )!
                                )!
                              : 0
                          );
                        }}
                      >
                        <img src={UserProfile} />
                        {props.Data[indexUser - 2].profilePhoto ? (
                          <img src={props.Data[indexUser - 2].profilePhoto} />
                        ) : (
                          <img src={UserProfile} />
                        )}
                      </div>
                    )}

                    {indexUser > 0 && (
                      <div
                        className="userHy"
                        onClick={() => {
                          setIndexUser(indexUser - 1);
                          setIndexHistory(
                            props.Data[indexUser - 1].historysData?.indexOf(
                              props.Data[indexUser - 1].historysData?.find(
                                (h: HistoryModel) => h.seen === false
                              )!
                            ) !== -1
                              ? props.Data[indexUser - 1].historysData?.indexOf(
                                  props.Data[indexUser - 1].historysData?.find(
                                    (h: HistoryModel) => h.seen === false
                                  )!
                                )!
                              : 0
                          );
                        }}
                      >
                        {props.Data[indexUser - 1].profilePhoto ? (
                          <img src={props.Data[indexUser - 1].profilePhoto} />
                        ) : (
                          <img src={UserProfile} />
                        )}
                      </div>
                    )}
                    {props.Data[indexUser].profilePhoto ? (
                      props.Data[indexUser].idUser === Auth._User?.user?.id ? (
                        <div className="userHy center_user_hy">
                          <img src={Auth._User?.profilePhoto} />
                        </div>
                      ) : (
                        <div className="userHy center_user_hy">
                          <img src={props.Data[indexUser].profilePhoto} />
                        </div>
                      )
                    ) : (
                      <div className="userHy center_user_hy">
                        <img src={UserProfile} />
                      </div>
                    )}

                    {indexUser + 1 < props.Data.length && (
                      <div
                        className="userHy"
                        onClick={() => {
                          setIndexUser(indexUser + 1);
                          setIndexHistory(
                            props.Data[indexUser + 1].historysData?.indexOf(
                              props.Data[indexUser + 1].historysData?.find(
                                (h: HistoryModel) => h.seen === false
                              )!
                            ) !== -1
                              ? props.Data[indexUser + 1].historysData?.indexOf(
                                  props.Data[indexUser + 1].historysData?.find(
                                    (h: HistoryModel) => h.seen === false
                                  )!
                                )!
                              : 0
                          );
                        }}
                      >
                        {props.Data[indexUser + 1].profilePhoto ? (
                          <img src={props.Data[indexUser + 1].profilePhoto} />
                        ) : (
                          <img src={UserProfile} />
                        )}
                      </div>
                    )}

                    {indexUser + 2 < props.Data.length && (
                      <div
                        className="userHy"
                        onClick={() => {
                          setIndexUser(indexUser + 2);
                          setIndexHistory(
                            props.Data[indexUser + 2].historysData?.indexOf(
                              props.Data[indexUser + 2].historysData?.find(
                                (h: HistoryModel) => h.seen === false
                              )!
                            ) !== -1
                              ? props.Data[indexUser + 2].historysData?.indexOf(
                                  props.Data[indexUser + 2].historysData?.find(
                                    (h: HistoryModel) => h.seen === false
                                  )!
                                )!
                              : 0
                          );
                        }}
                      >
                        {props.Data[indexUser + 2].profilePhoto ? (
                          <img src={props.Data[indexUser + 2].profilePhoto} />
                        ) : (
                          <img src={UserProfile} />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="progresbar_mother_box">
                    {props.Data[indexUser].historysData?.map(
                      (f: any, key: number) => (
                        <div key={key} className="container_progresbar_box">
                          <div className="progresbar_box_othersDiv"></div>
                          <div
                            className="progresbar_box"
                            style={{
                              backgroundColor: `${
                                indexHistory > key ? "#a66cff" : "white"
                              }`,
                            }}
                          >
                            {indexHistory === key && (
                              <>
                                {props.Data[indexUser].historysData![
                                  indexHistory
                                ].mediaData && (
                                  <HistoryProgressBar
                                    indexUser={indexUser}
                                    indexMap={key}
                                    indexH={indexHistory}
                                    NextHistory={NextHistory}
                                    running={running}
                                  />
                                )}
                              </>
                            )}
                          </div>
                          <div className="progresbar_box_othersDiv"></div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="ViewModalHYContainer">
                    {!historyUserList && (
                      <>
                        <div className="HyInterator">
                          {props.Data[indexUser].idUser ===
                          Auth._User?.user?.id ? (
                            <div className="ViewsDiv">
                              <span>
                                <FontAwesomeIcon
                                  style={{ color: "white" }}
                                  icon={faEye}
                                />{" "}
                                {
                                  props.Data[indexUser].historysData![
                                    indexHistory
                                  ].amountOfViews
                                }
                              </span>
                            </div>
                          ) : (
                            <div
                              className="LikeIcon"
                              onClick={() => {
                                if (
                                  props.Data[indexUser].historysData![
                                    indexHistory
                                  ].mediaData
                                ) {
                                  props.Data[indexUser].historysData![
                                    indexHistory
                                  ].liked
                                    ? setFillHistory(
                                        props.Data[indexUser].profilePhoto!,
                                        props.Data[indexUser].historysData![
                                          indexHistory
                                        ].mediaData!,
                                        props.Data[indexUser].historysData![
                                          indexHistory
                                        ].id!,
                                        props.Data[indexUser].historysData![
                                          indexHistory
                                        ].amountOfLikes! - 1,
                                        !props.Data[indexUser].historysData![
                                          indexHistory
                                        ].liked!,
                                        props.Data[indexUser].historysData![
                                          indexHistory
                                        ].seen!
                                      )
                                    : setFillHistory(
                                        props.Data[indexUser].profilePhoto!,
                                        props.Data[indexUser].historysData![
                                          indexHistory
                                        ].mediaData!,
                                        props.Data[indexUser].historysData![
                                          indexHistory
                                        ].id!,
                                        props.Data[indexUser].historysData![
                                          indexHistory
                                        ].amountOfLikes! + 1,
                                        !props.Data[indexUser].historysData![
                                          indexHistory
                                        ].liked!,
                                        props.Data[indexUser].historysData![
                                          indexHistory
                                        ].seen!
                                      );

                                  ActionLike(
                                    Auth._User?.user?.id!,
                                    props.Data[indexUser].historysData![
                                      indexHistory
                                    ].id!
                                  );
                                }
                              }}
                            >
                              {props.Data[indexUser].historysData![indexHistory]
                                .liked ? (
                                <FontAwesomeIcon
                                  style={{ color: "red" }}
                                  className="icon"
                                  icon={faHeart}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  style={{ color: "white" }}
                                  className="icon"
                                  icon={faHeart}
                                />
                              )}
                            </div>
                          )}

                          <div className="nameHy">
                            <p
                              className="nameHyP"
                              onClick={() =>
                                navigate(`/${props.Data[indexUser].username}`, {
                                  replace: true,
                                })
                              }
                            >
                              {props.Data[indexUser].username}
                            </p>
                            <p className="DateHyP">
                              {
                                props.Data[indexUser].historysData![
                                  indexHistory
                                ].createDate
                              }
                            </p>
                          </div>

                          <div
                            className="settingIcon"
                            onClick={() => {
                              if (
                                props.Data[indexUser].idUser ===
                                Auth._User?.user?.id
                              ) {
                                setWatchModalOP(true);
                                setRunning(false);
                              }
                            }}
                          >
                            {props.Data[indexUser].idUser ===
                              Auth._User?.user?.id && (
                              <FontAwesomeIcon
                                style={{ color: "white" }}
                                className="icon"
                                icon={faEllipsisVertical}
                              />
                            )}
                          </div>
                        </div>
                        {props.Data[indexUser].idUser ===
                          Auth._User?.user?.id && (
                          <div className="chevronup">
                            <div
                              className="chevronup-box"
                              onClick={() => {
                                setHistoryUserList(true);
                                setRunning(false);
                                GetUsersViewers(
                                  props.Data[indexUser].historysData![
                                    indexHistory
                                  ].id!
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                style={{ color: "white" }}
                                className="icon"
                                icon={faChevronUp}
                              />
                            </div>
                          </div>
                        )}
                        {props.Data[indexUser].historysData![indexHistory]
                          .mediaData ? (
                          <img
                            onMouseDown={() => {
                              setRunning(false);
                            }}
                            onMouseUp={() => {
                              setRunning(true);
                            }}
                            src={
                              props.Data[indexUser].historysData![indexHistory]
                                .mediaData
                            }
                            alt=""
                          />
                        ) : (
                          <div className="spiner">
                            <ChargerSlice />
                          </div>
                        )}
                      </>
                    )}

                    {historyUserList && (
                      <>
                        <div className="chevrondown">
                          <div
                            className="chevronup-box"
                            onClick={() => {
                              setHistoryUserList(false);
                              setRunning(true);
                              setUsersViewers(undefined);
                            }}
                          >
                            <FontAwesomeIcon
                              style={{ color: "white" }}
                              className="icon"
                              icon={faChevronDown}
                            />
                          </div>
                        </div>
                        {UsersViewers !== undefined &&
                        props.Data[indexUser].historysData![indexHistory]
                          .amountOfViews! > 0 ? (
                          <>
                            <div className="userhistoryconteiner">
                              <h6
                                style={{ color: "#e9ecef", marginLeft: "5px" }}
                              >
                                Vista por
                              </h6>
                              {UsersViewers.map(
                                (U: HistoryUserViewer, key: number) => (
                                  <div
                                    className="historyuserbox"
                                    key={key}
                                    onClick={() =>
                                      navigate(`/${U.username}`, {
                                        replace: true,
                                      })
                                    }
                                  >
                                    <div className="historyuserProfile">
                                      {U.profilePhoto ? (
                                        <img src={U.profilePhoto} alt="" />
                                      ) : (
                                        <img src={UserProfile} alt="" />
                                      )}
                                      {U.seen && (
                                        <div className="iconHy">
                                          <FontAwesomeIcon
                                            style={{ color: "red" }}
                                            icon={faHeart}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div className="historyuserName">
                                      <b
                                        style={{
                                          color: "#e9ecef",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        {U.name}
                                      </b>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </>
                        ) : UsersViewers === undefined &&
                          props.Data[indexUser].historysData![indexHistory]
                            .amountOfViews! > 0 ? (
                          <div className="spiner">
                            <ChargerSlice />
                          </div>
                        ) : (
                          <div className="lettle_HY">
                            <br />
                            <br />
                            <b style={{ color: "white" }}>
                              Aun no tiene visualizaciones.
                            </b>
                          </div>
                        )}

                        {/* {UsersViewers?.length! > 0 && props.Data[indexUser].historysData![indexHistory].amountOfViews! > 0 && ( */}
                      </>
                    )}
                  </div>
                </div>
                <div className="chevron_box">
                  {props.Data[indexUser].historysData?.length! >
                  indexHistory + 1 ? (
                    <div
                      onClick={() => {
                        setIndexHistory(indexHistory + 1);
                      }}
                    >
                      <FontAwesomeIcon
                        className="arrow_left_box"
                        icon={faChevronRight}
                      />
                    </div>
                  ) : props.Data.length > indexUser + 1 ? (
                    <div
                      onClick={() => {
                        setIndexUser(indexUser + 1);
                        setIndexHistory(
                          props.Data[indexUser + 1].historysData?.indexOf(
                            props.Data[indexUser + 1].historysData?.find(
                              (h: HistoryModel) => h.seen === false
                            )!
                          ) !== -1
                            ? props.Data[indexUser + 1].historysData?.indexOf(
                                props.Data[indexUser + 1].historysData?.find(
                                  (h: HistoryModel) => h.seen === false
                                )!
                              )!
                            : 0
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        className="arrow_left_box"
                        icon={faChevronRight}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              onClick={() => {
                props.setViewHistoryBox(false);
              }}
            >
              <FontAwesomeIcon className="closeIcon" icon={faX} />
            </div>
          </div>
        </div>
      )}

      {watchModalOP && (
        <PostOptions
          setViewOP={setWatchModalOP}
          Data={props.Data[indexUser].historysData![indexHistory]}
          FilterData={FilterMyHistorys}
          whereCode={false}
          setHyRunnig={setRunning}
        />
      )}
    </>
  );
};

export default HistoryViewer;
