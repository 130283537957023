import Http from '../Http/HttpClient';
import { IChangesService } from './../../Interface/Changes/IChangesService';
export class ChangesService implements IChangesService{
    async Email(New: string, IdUser: string): Promise<any> {
        let result = await new Promise<any>((resolve, reject) => {
            Http.put(`/api/Changes/Email?NewEmail=${New}&IdUser=${IdUser}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }
    async Password(Actual:string, New: string, IdUser: string): Promise<any> {
        let result = await new Promise<any>((resolve, reject) => {
            Http.put(`/api/Changes/Password?ActualPass=${Actual}&NewPass=${New}&IdUser=${IdUser}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }

    async DesactiveAccount(IdUser: string): Promise<any> {
        let result = await new Promise<any>((resolve, reject) => {
            Http.put(`/api/Changes/DesactivarAccount?IdUser=${IdUser}` )
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }

    async DeleteAccount(IdUser: string): Promise<any> {
        let result = await new Promise<any>((resolve, reject) => {
            Http.put(`/api/Changes/deleteAccount?IdUser=${IdUser}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }
    async Name(New: string, IdUser: string): Promise<any> {
        console.log(New, IdUser);
        
        let result = await new Promise<any>((resolve, reject) => {
            Http.put(`/api/Changes/Name?NewName=${New}&IdUser=${IdUser}`)
                .then((res) => {
                    resolve(res);
                    
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }
    async Username(New: string, IdUser: string): Promise<any> {
        let result = await new Promise<any>((resolve, reject) => {
            Http.put(`/api/Changes/Username?NewUsername=${New}&IdUser=${IdUser}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }
    async Presentation(New: string, IdUser: string): Promise<any> {
        let result = await new Promise<any>((resolve, reject) => {
            Http.put(`/api/Changes/Presentation?NewPresentation=${New}&IdUser=${IdUser}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }
    async Phone(New: string, IdUser: string): Promise<any> {
        let result = await new Promise<any>((resolve, reject) => {
            Http.put(`/api/Changes/Phone?NewPhone=${New}&IdUser=${IdUser}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }

}

const Changes = new ChangesService();
export default Changes;