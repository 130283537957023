import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './Interactor.css';
import PopularContentCard from './Elemets/PopularContentCard/PopularContentCard';
import RoundProfileCard from './Elemets/RoundProfileCard/RoundProfileCard';
import PhotoMosaic from './Elemets/PostMosaic/PostMosaic';
import { PostModel } from '../../Models/Post/PostModel';
import TendenciaCharger from '../Charger/TendenciaCharger/TendenciaCharger';
import MosaicoCharger from '../Charger/MosaicoCharger/MosaicoCharger';
import { AppContext } from '../../context/AppContext';
const Interactor = () => {

    useEffect(() => {
        var back = document.getElementById("tendencia-box");
        document.querySelector('.back_buton_history')?.addEventListener('click', () => {
            back!.scrollLeft -= 200;
        })
        document.querySelector('.come_buton_history')?.addEventListener('click', () => {
            back!.scrollLeft += 200;
        })

        var userbox = document.getElementById("users-box");
        document.querySelector('.back_buton_users')?.addEventListener('click', () => {
            userbox!.scrollLeft -= 50;
        })
        document.querySelector('.come_buton_users')?.addEventListener('click', () => {
            userbox!.scrollLeft += 50;
        })
    });

    const Context = useContext(AppContext);
    if (!Context) {
        // Manejar el caso cuando el contexto es nulo
        return null;
    }
    const {
        tendenciasCharger,
        tendencias,
        mosaicosCharger,
        mosaicos,
        users
    } = Context;

    return (
        <div className="explorer-section">
            <div className="popular-content">
                <h3 style={{ marginLeft: '40px' }}>Tendencia</h3>
                <div className='popular-content-div'>
                    <div>
                        <div className="iconarrowsbox">
                            <FontAwesomeIcon
                                className="icon back_buton_history"
                                icon={faChevronLeft}
                            />
                        </div>
                    </div>
                    <div className='container-tendencia' id="tendencia-box">
                        {tendenciasCharger && (
                            <TendenciaCharger />
                        )}
                        {tendencias!.map((e: PostModel, key: number) =>
                            <PopularContentCard key={key} publication={e} tendencia={key} />
                        )}
                    </div>
                    <div>
                        <div className="iconarrowsbox">
                            <FontAwesomeIcon
                                className="icon come_buton_history"
                                icon={faChevronRight}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="featured-users" id="users-box">
                <h3>Usuarios Destacados</h3>
                {users!.map((e: any, key: number) => <RoundProfileCard key={key} name={e.name} username={e.username} idMediaDataProfile={e.perfilData.idMediaDataProfile} />)}
            </div>
            <div className='featured-users-boton'>
                <div className="iconarrowsbox circle-boton back_buton_users">
                    <FontAwesomeIcon
                        className="icon circle-boton-icon"
                        icon={faChevronLeft}
                    />
                </div>
                <div className="iconarrowsbox circle-boton come_buton_users">
                    <FontAwesomeIcon
                        className="icon circle-boton-icon"
                        icon={faChevronRight}
                    />
                </div>
            </div>


            {mosaicosCharger && (
                <MosaicoCharger />
            )}
            <PhotoMosaic posts={mosaicos} />


        </div>
    );
};

export default Interactor;