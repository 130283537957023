import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faX,
    faArrowRight,
    faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Auth from '../../Services/Auth/AuthService';
import UserProfile from "../../assets/img/UserProfile.jpg";
import './CropperC.css'
import Post from "../../Services/Post/PostService";
import { PostParams } from "../../Models/Post/PostParams";
import History from "../../Services/History/HistoryService";
import imageCompression from "browser-image-compression";
import MediaData from "../../Services/MediaData/MediaDataService";
import { MediaDataModel } from "../../Models/MediaData/MediaDataModel";

interface ICropper {
    src: any
    setImage: Function
    setInputValue: Function
    aspectRatio: number
    setPhotos?: Function
    setOnLoadPhoto?: Function
    setFooterViewer?: Function
    optionCut: number
}

const CropperC = (props: ICropper) => {
    const [cropPreviewData, setCropPreviewData] = useState("#")
    const [cropper, setCropper] = useState<Cropper>()
    const imageRef = useRef<HTMLImageElement>(null)

    const [descripcion, setDescripcion] = useState<string>('')

    const [postSteps, setPostSteps] = useState<number>(0)
    const [spinner, setSpinner] = useState<boolean>(false)


    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const getPreViewCropDataPost = () => {
        if (typeof cropper !== "undefined") {
            setCropPreviewData(cropper.getCroppedCanvas().toDataURL());
        }
    }

    const getCropData = async () => {
        return new Promise<string | undefined>(resolve => {
            if (typeof cropper !== "undefined") {
                const croppedCanvas = cropper.getCroppedCanvas();

                croppedCanvas.toBlob(async (blob) => {
                    if (blob) {
                        const file = new File([blob], "compressed_image.jpg", {
                            type: blob.type,
                            lastModified: Date.now(),
                        });

                        const options = {
                            maxSizeMB: 0.5,
                            maxWidthOrHeight: 800,
                            useWebWorker: true,
                        };

                        try {
                            const compressedFile = await imageCompression(file, options);
                            const compressedDataURL = await imageCompression.getDataUrlFromFile(compressedFile);
                            resolve(compressedDataURL);
                        } catch (error) {
                            console.error("Error al comprimir la imagen:", error);
                            resolve(undefined);
                        }
                    }
                });
            } else {
                resolve(undefined);
            }
        });
    };

    const handleSubmit = async () => {

        const MockupNewPost: PostParams = {
            id: "",
            idUser: Auth._User?.user?.id,
            photo: cropPreviewData,
            description: descripcion,
        }

        setPostSteps(0);
        props.setImage!(null);
        props.setInputValue!("");

        if (props.setOnLoadPhoto) {
            props.setOnLoadPhoto!(MockupNewPost)
            props.setFooterViewer!(false);
        }

        try {
            const croppedDataURL = await getCropData();

            if (croppedDataURL !== undefined && croppedDataURL !== "#") {
                const newPost: PostParams = {
                    id: "",
                    idUser: Auth._User?.user?.id,
                    photo: croppedDataURL,
                    description: descripcion,
                };

                const response = await Post.Create(newPost);

                if (props.setPhotos) {
                    props.setPhotos(response.data);
                    props.setOnLoadPhoto!(null);
                    props.setFooterViewer!(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const SaveHistory = async () => {

        setPostSteps(0);
        props.setImage!(null)
        props.setInputValue!("")
        setSpinner(false)

        try {
            const croppedDataURL = await getCropData();

            if (croppedDataURL !== undefined && croppedDataURL !== "#") {

                const response = await History.Create(Auth._User?.user?.id!,croppedDataURL);

                if (props.setPhotos) {
                    props.setPhotos(response.data);
                    props.setOnLoadPhoto!(null);
                    props.setFooterViewer!(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const SaveCoverPhoto = async () => {
        try {
            const croppedDataURL = await getCropData();

            if (isMounted.current && croppedDataURL !== undefined && croppedDataURL !== "#") {

                const p: MediaDataModel = {
                    Id: "",
                    Value: croppedDataURL,
                    Type: ""
                }

                await MediaData.CreateCover(Auth._User?.user?.id!, p);

                if (isMounted.current && props.setPhotos) {
                    props.setPhotos(croppedDataURL);
                    Auth.ChangeCoverPhoto(croppedDataURL)
                    setPostSteps(0);
                    props.setImage!(null);
                    props.setInputValue!("");
                    setSpinner(false);
                }
            }
        } catch (error) {
            if (isMounted.current) {
                console.error(error);
                setPostSteps(0);
                props.setImage!(null);
                props.setInputValue!("");
                setSpinner(false);
            }
        }
    }

    const SaveProfifePhoto = async () => {
        try {
            const croppedDataURL = await getCropData();

            if (isMounted.current && croppedDataURL !== undefined && croppedDataURL !== "#") {

                const p: MediaDataModel = {
                    Id: "",
                    Value: croppedDataURL,
                    Type: ""
                }

                await MediaData.CreateProfile(Auth._User?.user?.id!, p);

                if (isMounted.current && props.setPhotos) {
                    props.setPhotos(croppedDataURL);
                    Auth.ChangeProfilePhoto(croppedDataURL)
                    setPostSteps(0);
                    props.setImage!(null);
                    props.setInputValue!("");
                    setSpinner(false);
                }
            }
        } catch (error) {
            if (isMounted.current) {
                console.error(error);
                setPostSteps(0);
                props.setImage!(null);
                props.setInputValue!("");
                setSpinner(false);
            }
        }
    };


    return (
        <div className="modal_box">
            <div className="modal_container">
                {postSteps === 0 && (
                    <div className="modal_button">
                        <div
                            onClick={() => {
                                props.setImage!(null)
                                props.setInputValue!("");
                            }}
                        >
                            <FontAwesomeIcon icon={faX} />
                        </div>
                        {props.aspectRatio !== 1 && props.optionCut === 1
                            ? (
                                <>
                                    {spinner
                                        ? (
                                            <div className="col-md-2 text-center texto-publi">
                                                <div className="spinner-border text-primary coment-spinner" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        )
                                        : (<div>
                                            <b style={{ color: '#1877f2' }} onClick={() => { setSpinner(true); SaveHistory() }}>Compartir</b>
                                        </div>)
                                    }
                                </>
                            )
                            : props.aspectRatio !== 1 && props.optionCut !== 1
                                ?
                                (
                                    <>
                                        {spinner
                                            ? (
                                                <div className="col-md-2 text-center texto-publi">
                                                    <div className="spinner-border text-primary coment-spinner" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            )
                                            : (<div>
                                                <b style={{ color: '#1877f2' }} onClick={() => { setSpinner(true); SaveCoverPhoto() }}>Guardar</b>
                                            </div>)
                                        }
                                    </>
                                )
                                : props.aspectRatio === 1 && props.optionCut === 1
                                    ?
                                    (
                                        <div
                                            onClick={() => { setPostSteps(1); getPreViewCropDataPost(); }}>
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </div>
                                    )
                                    :
                                    (
                                        <>
                                            {spinner
                                                ? (
                                                    <div className="col-md-2 text-center texto-publi">
                                                        <div className="spinner-border text-primary coment-spinner" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                )
                                                : (<div>
                                                    <b style={{ color: '#1877f2' }} onClick={() => { SaveProfifePhoto(); setSpinner(true) }}>Guardar</b>
                                                </div>)
                                            }
                                        </>
                                    )
                        }

                        {/* {props.aspectRatio === 1 && props.optionCut === 1 && (
                            <div
                                onClick={() => { setPostSteps(1); getCropDataPost(); }}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        )} */}
                    </div>
                )}

                {postSteps === 1 && props.aspectRatio === 1 && (
                    <div className="modal_button">
                        <div
                            onClick={() => { setPostSteps(0) }}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <div onClick={handleSubmit}>
                            <b style={{ color: '#1877f2' }}>Compartir</b>
                        </div>
                    </div>
                )}


                {postSteps === 0 && (
                    <div className="img_container">
                        <Cropper
                            style={{ height: "100%" }}
                            // initialAspectRatio={1}
                            aspectRatio={props.aspectRatio}
                            src={props.src}
                            ref={imageRef}
                            viewMode={2}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            checkOrientation={false}
                            preview='.preview'
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />
                    </div>
                )}
                {postSteps === 1 && props.aspectRatio === 1 && (
                    <div className='previewPost row'>
                        <div className="PostImgMini col-md-3">
                            <img src={cropPreviewData} alt="" />
                        </div>
                        <div className='tex-area-post col-md-9'>
                            {Auth._User?.profilePhoto ? <img src={Auth._User?.profilePhoto} alt="" /> : <img src={UserProfile} alt="" />}
                            <b> {Auth._User?.user?.name}</b>
                            <textarea value={descripcion} onChange={(e: any) => { setDescripcion(e.target.value) }} placeholder='Escribe una descripcion...'></textarea>
                        </div>
                    </div>
                )}
            </div>
        </div>

    )
}
export default CropperC