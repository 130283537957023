import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Icon from '../assets/img/icon.png'
import Logo from '../assets/img/Logo.png'

const NotFounf = () => {

  useEffect(() => {
    document.title = `${process.env.REACT_APP_TITTLE_BASE} • Pagina no encontrada`;
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, []);

  return (
    <div>
      <div style={{ margin: 'auto', marginTop: '100px', maxWidth: '200px', maxHeight: '90%' }}>
        <img src={Icon} style={{ width: '100%', objectFit: 'cover', objectPosition: 'center center' }} />
        <br />
        <img src={Logo} style={{ width: '100%', objectFit: 'cover', objectPosition: 'center center', marginTop: '-40px' }} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <h3>Pagina no encontrado</h3>
        <b>Esta pagina no existe o es pocible que haya sido eliminada.
          <Link to="/"><span>Volver a Aycoro.</span></Link>
        </b>
      </div>
    </div>
  )
}

export default NotFounf