import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { CurrencyConversation } from "../../../../Models/Message/CurrencyConversation";
import { MessageParams } from "../../../../Models/Message/MessageParams";
import Auth from "../../../../Services/Auth/AuthService";
import Chat_S from "../../../../Services/Chat/ChatService";
import "./InboxListUsersBox.css";
import InboxUser from "./InboxUser/InboxUser";

interface IListBox {
  usersConnecting: any;
  NewcurrencyConversation: MessageParams;
}

const InboxListUsersBox = ({
  usersConnecting,
  NewcurrencyConversation,
}: IListBox) => {
  const [currencyConversations, setcurrencyConversations] = useState<
    CurrencyConversation[]
  >([]);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const res = await Chat_S.GetCurrencyConversation(
          Auth._User?.user?.id!,
          1
        );
        setcurrencyConversations(res.data);
      } catch (e) {
        console.error(e);
      }
    };

    fetchConversations();
  }, []);

  useEffect(() => {
    if (NewcurrencyConversation) {
      if (NewcurrencyConversation.idUserSender === Auth._User?.user?.id) {
        addNewCurrency(NewcurrencyConversation.idMediaDataProfileReceiver!);
      } else if (
        NewcurrencyConversation.idUserReceiver === Auth._User?.user?.id
      ) {
        addNewCurrency(NewcurrencyConversation.idMediaDataProfileSender!);
      }
    }
    console.log(currencyConversations);
  }, [NewcurrencyConversation]);

  const addNewCurrency = (id: string) => {
    const newCurrency: CurrencyConversation = {
      idChat: NewcurrencyConversation.id,
      nameGroup: "",
      name: NewcurrencyConversation.nameGroup
        ? NewcurrencyConversation.nameGroup
        : NewcurrencyConversation.idUserSender === Auth._User?.user?.id
        ? NewcurrencyConversation.nameReceiver
        : NewcurrencyConversation.nameSender,
      username:
        NewcurrencyConversation.idUserSender === Auth._User?.user?.id
          ? NewcurrencyConversation.usernameReceiver
          : NewcurrencyConversation.usernameSender,
      verify: 1,
      isDirect: NewcurrencyConversation.isDirect,
      messageType: NewcurrencyConversation.type,
      isRead:
        NewcurrencyConversation.idUserSender === Auth._User?.user?.id
          ? true
          : false,
      unReadMessages:
        currencyConversations.find(
          (c) => c.idChat === NewcurrencyConversation!.id
        )?.unReadMessages! + 1 || 1,
      idMediaDataProfile: id,
      profilePhoto: "",
      messageValue: NewcurrencyConversation.messageValue,
      createDate: NewcurrencyConversation.createDate,
    };
    setcurrencyConversations((prev) => [
      newCurrency,
      ...prev.filter((cr) => cr.idChat !== NewcurrencyConversation.id),
    ]);
  };

  return (
    <div className="InbuxUsersBox">
      <div className="headerUserBox">
        <div className="input-wrapper col-md-10">
          <input type="search" className="input" placeholder="Search" />
          <FontAwesomeIcon className="input-icon" icon={faMagnifyingGlass} />
        </div>
      </div>
      <div className="conversations">
        {currencyConversations.map((e: CurrencyConversation) => (
          <InboxUser usersConnecting={usersConnecting} CC={e} key={e.idChat} />
        ))}
      </div>
    </div>
  );
};

export default InboxListUsersBox;
