import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ComentModel } from '../../Models/Coment/ComentModel'
import Coment from '../../Services/Coments/ComentService'

interface IComentOPtion {
    setCOPView: Function
    setECMView: Function
    coment: ComentModel
    onDelete: Function
}

const ComentOptions = ({ setCOPView, setECMView, coment, onDelete }: IComentOPtion) => {


    const Delete = (id: string) => {
        Coment.Delete(id)
            .then((e: any) => {
                onDelete()
                setCOPView(false)
            })
            .catch((err: any) => {
                console.log(err);
            })
    }
    return (
        <div className="OPmodal_box">
            <div className="OPmodal_container">
                <div className="OPmodal_button">
                    <div
                        onClick={() => {
                            setCOPView(false)
                        }}
                        className='closeBox'>
                        <FontAwesomeIcon icon={faX} />
                    </div>

                    <div className="OptionsList">
                        <div className='OPoption' onClick={() => {
                            Delete(coment.id!)
                        }}>
                            <b id='Eliminar'>Eliminar</b>
                        </div>
                        <div className='OPoption' onClick={() => {
                            setECMView(true)
                            setCOPView(false)
                        }}>
                            <b>Editar</b>
                        </div>
                        <div className='OPoption' onClick={() => setCOPView(false)}>
                            <b>Cancelar</b>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default ComentOptions