import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import LoginForm from "../Modules/LoginForm/LoginForm";
import Auth from "../Services/Auth/AuthService";

function Login() {
  return Auth._Authenticated ? <Navigate to="/"/> :  <LoginForm />;
}

export default Login;
