import React, { useContext, useEffect } from "react";
import HistoryComponet from "../Modules/Historys/HistoryComponet";
import Footer from "../Modules/Footer/Footer";
import Publications from "../Modules/Publications/Publications";
import Auth from "../Services/Auth/AuthService";
import { AppContext } from "../context/AppContext";
import RecordsExhaustedMessage from "../Modules/GoTo/RecordsExhaustedMessage";

const Home = () => {
  const Context = useContext(AppContext);
  if (!Context) {
    // Manejar el caso cuando el contexto es nulo
    return null;
  }
  const { searchVisible,
    buttonsVisible,
    setButtonsVisible_F,
    historyList,
    setHistoryList_F,
    myhistorys,
    ConcatMyHistorys,
    setMyHistorys_F,
    setPhoto,
    setOnLoadPhoto,
    setFooterViewer_F,
    footerViewer,
    BdPost,
    OnLoadPost,
    setBdPost_F,
    chargingPost,
    goToExplorerFromIndex } = Context;

  return (
    <>
      {!searchVisible && (
        <>
          <HistoryComponet
            historyList={historyList!}
            setHistorys={setHistoryList_F}
            myhistorys={myhistorys!}
            addMyHistory={ConcatMyHistorys}
            setMyHistorys={setMyHistorys_F}
          />
          <Footer
            buttonVisible={buttonsVisible}
            setButtonsVisible={setButtonsVisible_F}
            setPhotos={setPhoto}
            setOnLoadPhoto={setOnLoadPhoto}
            setFooterViewer={setFooterViewer_F}
            footerViewer={footerViewer}
            setMyHistorys={ConcatMyHistorys}
          />
          <Publications
            iduser={Auth._User?.user?.id}
            Post={BdPost!}
            OnLoadPost={OnLoadPost}
            setPost={setBdPost_F}
            charging={chargingPost}
          />
          {goToExplorerFromIndex && <RecordsExhaustedMessage />}
        </>
      )}
    </>
  );
};

export default Home;
