import React, { useContext } from "react";
import HistoryComponet from "../Modules/Historys/HistoryComponet";
import Footer from "../Modules/Footer/Footer";
import { AppContext } from "../context/AppContext";
import RecordsExhaustedMessage from "../Modules/GoTo/RecordsExhaustedMessage";
import PublicationOnLoad from "../Modules/Publications/Publication/PublicationOnLoad";
import { PostModel } from "../Models/Post/PostModel";
import Publication from "../Modules/Publications/Publication/Publication";
import IndexPostCharger from "../Modules/Charger/IndexPostCharger/IndexPostCharger";
import { PostStatus } from "../constants/Status";

const Home = () => {
  const Context = useContext(AppContext);
  if (!Context) {
    // Manejar el caso cuando el contexto es nulo
    return null;
  }
  const {
    searchVisible,
    buttonsVisible,
    setButtonsVisible_F,
    historyList,
    setHistoryList_F,
    myhistorys,
    ConcatMyHistorys,
    setMyHistorys_F,
    setPhoto,
    setOnLoadPhoto,
    setFooterViewer_F,
    footerViewer,
    BdPost,
    OnLoadPost,
    setBdPost_F,
    chargingPost,
    goToExplorerFromIndex,
  } = Context;

  const updatePost = (
    id: string,
    updates: Partial<
      Pick<PostModel, "description" | "status" | "likes" | "comments" | "liked">
    >
  ) => {    
    const updatedPosts = BdPost!.map((post: PostModel) =>
      post.id === id ? { ...post, ...updates } : post
    );
    setBdPost_F(updatedPosts);
  };

  return (
    <>
      {!searchVisible && (
        <>
          <HistoryComponet
            historyList={historyList!}
            setHistorys={setHistoryList_F}
            myhistorys={myhistorys!}
            addMyHistory={ConcatMyHistorys}
            setMyHistorys={setMyHistorys_F}
          />
          <Footer
            buttonVisible={buttonsVisible}
            setButtonsVisible={setButtonsVisible_F}
            setPhotos={setPhoto}
            setOnLoadPhoto={setOnLoadPhoto}
            setFooterViewer={setFooterViewer_F}
            footerViewer={footerViewer}
            setMyHistorys={ConcatMyHistorys}
          />
          {OnLoadPost && <PublicationOnLoad publication={OnLoadPost} />}
          {BdPost!.map((p: PostModel, key: number) => {
            if (
              p.status == PostStatus.EDITED ||
              p.status == PostStatus.PUBLISHED
            ) {
              return <Publication key={key} publication={p} updatePost={updatePost} />;
            }
          })}
          {chargingPost && <IndexPostCharger />}
          {goToExplorerFromIndex && <RecordsExhaustedMessage />}
        </>
      )}
    </>
  );
};

export default Home;
