import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserLoginModel } from "../../Models/User/UserLoginModel";
import User from "../../Services/User/UserService";
import ChargerSlice from "../ChargerSlice/ChargerSlice";
import IconLogo from "../../assets/img/icon.png";
import Logo from "../../assets/img/Logo.png";
import "./LoginForm.css";
import AlertError from "../AlertError/AlertError";
import FooterBlog from "../FooterBlog/FooterBlog";

function LoginForm() {
  const navigate = useNavigate();
  const [key, setKey] = useState<string | undefined>(undefined);
  const [pass, setPass] = useState<string | undefined>(undefined);
  const [checking, setChecking] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [errorIsActive, setErrorIsActive] = useState<boolean>(false);

  useEffect(() => {
    // Cambiar el título de la página cuando el componente se monta
    document.title = `${process.env.REACT_APP_TITTLE_BASE} • Inicia Sesion`;

    // También puedes restablecer el título cuando el componente se desmonta
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, []);

  const Logear = () => {
    setChecking(!checking);
    let data: UserLoginModel = {
      Key: key!.toLowerCase(),
      Password: pass,
    };

    if (data.Key !== "" && data.Password !== "") {
      User.LogIn(data)
        .then((res: any) => {
          if (res.data.code == 200) {
            navigate(`/`, { replace: true });
          }
          if (res.data.code == 204) {
            setChecking(false);
            setError(res.data.message);
            setErrorIsActive(!errorIsActive);
          }
          
        })
        .catch((e: any) => {
          setChecking(false);
          setError(e);
          setErrorIsActive(!errorIsActive);
        });
    } else {
      if (data.Password === "") {
        setChecking(false);
        setError("Debes colocar tu contraseña");
        setErrorIsActive(!errorIsActive);
      }

      if (data.Key === "") {
        setChecking(false);
        setError("Colocar tu usuario, email o numero");
        setErrorIsActive(!errorIsActive);
      }
    }
  };

  return (
    <div className="cont">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {!checking && (
        <>
          <div className="conteiner-login">
            <div className="formu-box">
              <div className="con-box">
                <div className="logo_login">
                  <img src={IconLogo} />
                </div>
                <div className="logo_login2">
                  <img src={Logo} />
                </div>
                {error && <AlertError error={error} />}
                <div className="form-horizontal">
                  <div className="form-group">
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Teléfono, usuario o correo electrónico"
                        onChange={(e) => setKey(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Ingrese su Contraseña"
                        onChange={(e) => setPass(e.target.value)}
                        required
                      />
                      <span className="link_password">
                        <span>Olvidaste tu contraseña?</span>
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-offset">
                      <button
                        className="btn btn-success button-log"
                        onClick={Logear}
                      >
                        Iniciar Sesion
                      </button>
                    </div>
                  </div>

                  <div className="signup_link">
                    <span>Aun no tienes cuenta? </span>
                    <Link to="/signup">
                      <span>Registrate</span>
                    </Link>
                    <br />
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <FooterBlog />
        </>
      )}

      {checking && <ChargerSlice />}
    </div>
  );
}

export default LoginForm;
