import React, { useContext, useEffect, useState } from "react";
import "./Nav.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/Logo.png";
import IconLogo from "../../assets/img/icon.png";
import UserProfile from "../../assets/img/UserProfile.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faGear,
  faMessage,
  faMagnifyingGlass,
  faCompass,
  faMoon,
  faArrowUpRightFromSquare,
  faHome,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Auth from "../../Services/Auth/AuthService";
import User from "../../Services/User/UserService";
import { ThemeContext } from "../../context/ThemeContext";

interface INav {
  searchVisible: boolean;
  setSearchVisible: Function;
  setUsersSearch: Function;
  searchInput: boolean;
  setSearchInput: Function;
  closeConnection: Function;
}

const Nav = (props: INav) => {
  const navigate = useNavigate();
  const Theme = useContext(ThemeContext);

  const [SearchText, setSearchText] = useState<string>('')
  

  useEffect(() => {
    if (!props.searchInput) {
      setSearchText('')
    }
  }, [props.searchInput, SearchText])

  if (!Theme) {
    // Manejar el caso cuando el contexto es nulo
    return null;
  }

  const { theme, setThemes } = Theme;

  const Dark = () => {
    const newThemes = theme === "light" ? "dark" : "light";
    setThemes(newThemes);
  };

  const Search = (e: string) => {
    if (e !== undefined && e !== "") {
      props.setSearchVisible(true);
      User.SearchUser(e, 1)
        .then((res: any) => {
          props.setUsersSearch(res.data);
        })
        .catch((res: any) => {
          console.log(res);
        });
    } else {
      props.setSearchVisible(false);
    }
  };

  return (
    <div className="fixed">
      <div className="contain">
        <div className="row">
          <div className="conteiner">
            <div className="logoBox">
              <Link to="/">
                <div className="logo" title="Aycoro">
                  <img src={IconLogo} id="IconLogo" alt="" />
                  <img src={Logo} id="TextLogo" alt="" />
                </div>
              </Link>
            </div>
            <div className="searchBox">
              <div className="search">
                {props.searchInput && (
                  <input
                    className="InputClass"
                    type="text"
                    name="SearchInput"
                    placeholder="Buscar"
                    autoComplete="off"
                    autoFocus
                    value={SearchText}
                    id="searchInputId"
                    onChange={(e) => {
                      setSearchText(e.target.value)
                      Search(e.target.value)
                    }}
                  />
                )}
                <div
                  className="Icosearch"
                  onClick={() => {
                    props.setSearchInput(!props.searchInput);
                  }}
                >
                  <FontAwesomeIcon
                    className="searchIcon"
                    icon={faMagnifyingGlass}
                  />
                </div>
              </div>
            </div>
            <div className="NavIconBox">
              <div className="icosNav">
                <div className="Box searchdisplay"
                  onClick={() => {
                    props.setSearchInput(!props.searchInput);
                  }}>
                  <FontAwesomeIcon
                    className="icon"
                    icon={faMagnifyingGlass}
                  />
                </div>
                <Link to="/">
                  <div className="Box">
                    <FontAwesomeIcon
                      title="Inicio"
                      className="icon"
                      icon={faHome}
                    />
                  </div>
                </Link>
                <Link to="/explorer">
                  <div className="Box">
                    <FontAwesomeIcon
                      title="Explorador"
                      className="icon"
                      icon={faCompass}
                    />
                  </div>
                </Link>
                <Link to="/inbox">
                  <div className="Box">
                    <FontAwesomeIcon
                      title="Mensajes"
                      className="icon"
                      icon={faMessage}
                    />
                  </div>
                </Link>
                <div className="Box">
                  <FontAwesomeIcon
                    title="Notificaciones"
                    className="icon"
                    icon={faBell}
                  />
                </div>
                <div className="Box">
                  <div
                    className="dropdown-toggle perfil"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    title={Auth._User?.user?.name}
                  >
                    {Auth._User?.profilePhoto ? <img src={Auth._User?.profilePhoto} alt="" /> : <img src={UserProfile} alt="" />}
                  </div>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      id="tittle_dropdown_1"
                      onClick={() =>
                        navigate(`/${Auth._User?.user?.username}`, { replace: true })
                      }
                    >
                      <div className="hipePerfil">
                        {Auth._User?.profilePhoto ? <img src={Auth._User?.profilePhoto} alt="" /> : <img src={UserProfile} alt="" />}
                      </div>
                      <div className="lettle-perfil-drop">
                        <strong>{Auth._User?.user?.name}</strong>
                      </div>
                    </div>
                    <div
                      className="dropdown-item"
                      id="tittle_dropdown_2"
                      onClick={Dark}
                    >
                      <div className="icon-drop">
                        <FontAwesomeIcon
                          className="icon-dropdown"
                          icon={faMoon}
                        />
                      </div>
                      <div className="lettle-drop">
                        <strong>Modo Oscuro</strong>
                      </div>
                    </div>
                    <div className="dropdown-item" id="tittle_dropdown_3" onClick={() => navigate(`/settings`, { replace: true })}>
                      <div className="icon-drop">
                        <FontAwesomeIcon
                          className="icon-dropdown"
                          icon={faGear}
                        />
                      </div>
                      <div className="lettle-drop">
                        <strong>Ajustes</strong>
                      </div>
                    </div>
                    <div
                      className="dropdown-item"
                      id="tittle_dropdown_4"
                      onClick={() => props.closeConnection()}
                    >
                      <div className="icon-drop">
                        <FontAwesomeIcon
                          className="icon-dropdown"
                          icon={faArrowUpRightFromSquare}
                        />
                      </div>
                      <div className="lettle-drop">
                        <strong>Cerrar sesion</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="hr" />

    </div>
  );
};

export default Nav;
