import { IChatService } from "../../Interface/Chat/IChatService";
import { MessageSaveParams } from "../../Models/Message/MessageSaveParams";
import Http from "../Http/HttpClient";

export class ChatService implements IChatService {
    async Save(message: MessageSaveParams | undefined): Promise<any> {
        let save = await new Promise<any>((resolve, reject) => {
            Http.post(`api/Chat/Message`,message)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return save;
    }
    async IsExist(idU1: string | undefined, idU2: string | undefined): Promise<any> {
        let isExist = await new Promise<any>((resolve, reject) => {
            Http.post(`/api/Chat?idU1=${idU1}&idU2=${idU2}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return isExist;
    }
    async CreateGroup(Dta: [any] | undefined): Promise<any> {
        let Create = await new Promise<any>((resolve, reject) => {
            Http.post(`/api/Chat/CreateGroup `, Dta)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return Create;
    }
    async GetCurrencyConversation(id: string, section:number): Promise<any> {
        let GetCC = await new Promise<any>((resolve, reject) => {
            Http.get(`/api/Chat/GetCurrencyConversation?idUser=${id}&section=${section}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return GetCC;
    }
    async GetAllMessages(id: string , section: number): Promise<any> {
        let messages = await new Promise<any>((resolve, reject) => {
            Http.get(`/api/Chat?idChat=${id}&section=${section}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return messages;
    }
    async DeleteMessage(id: string | undefined): Promise<any> {
        let deleteM = await new Promise<any>((resolve, reject) => {
            Http.put(`/api/Chat?id=${id}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return deleteM;
    }

}
const Chat_S = new ChatService();
export default Chat_S;