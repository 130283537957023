import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './FooterBlog.css'

const FooterBlog = () => {
    const navigate = useNavigate();
    return (
        <>
            {/* {console.log(window.location)}
            {console.log(window.location.pathname.substring(0, 10))} */}

            <div className="footer_blog">
                <div className='contain_option_blog'>
                    <span onClick={() => window.location.pathname.substring(0, 10) !== "/comunity/" ? window.open(window.location.origin + '/comunity/aycoro', '_blank') : navigate(`/comunity/aycoro`, { replace: true })}>Aycoro</span>

                    <span onClick={() => window.location.pathname.substring(0, 10) !== "/comunity/" ? window.open(window.location.origin + '/comunity/ayuda', '_blank') : navigate(`/comunity/ayuda`, { replace: true })}>Ayuda</span>

                    <span onClick={() => window.location.pathname.substring(0, 10) !== "/comunity/" ? window.open(window.location.origin + '/comunity/contacto', '_blank') : navigate(`/comunity/contacto`, { replace: true })}>Contactos</span>

                    {/* <a comunity/perfiles ><span>Perfiles</span></a> */}

                    <span onClick={() => window.location.pathname.substring(0, 10) !== "/comunity/" ? window.open(window.location.origin + '/comunity/terminos', '_blank') : navigate(`/comunity/terminos`, { replace: true })}>Terminos y condiciones</span>

                </div>

                <span className="version">©Aycoro 2023</span>

                <br />
                <br />
                <br />
            </div>
        </>
    )
}

export default FooterBlog