import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faX } from "@fortawesome/free-solid-svg-icons";
import Auth from "../../../../../Services/Auth/AuthService";
import Chat_S from "../../../../../Services/Chat/ChatService";
import { MessageParams } from "../../../../../Models/Message/MessageParams";
import { MessageSaveParams } from "../../../../../Models/Message/MessageSaveParams";
import { UserPerfilModel } from "../../../../../Models/User/UserPerfilModel";
import Data from "../../../../../Services/Data/Data";
import imageCompression from "browser-image-compression";
import MediaData from "../../../../../Services/MediaData/MediaDataService";
import { MediaDataModel } from "../../../../../Models/MediaData/MediaDataModel";
import { MessageType } from "../../../../../constants/Types";
import "./SendMessageForm.css";

interface ISendMessageForm {
  userData: UserPerfilModel | undefined;
  sendMessage: Function;
  idChat: string | undefined;
}

const SendMessageForm = (props: ISendMessageForm) => {
  const [message, setMessage] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const saveMessage = (m: MessageSaveParams) => {
    Chat_S.Save(m).catch((e: any) => console.error(e));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  const handleSave = async () => {
    if (image) {
      const compressedImage = await compressImage(image);
      if (compressedImage) {
        const mediaData: MediaDataModel = {
          Id: "",
          Value: compressedImage,
          Type: "",
        };
        await saveMediaData(mediaData);
      }
    } else {
      sendMessage(undefined);
    }
  };

  const saveMediaData = async (media: MediaDataModel) => {
    const mediaResult = await MediaData.Create(media);
    sendMessage(mediaResult.data.id);
  };

  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return await imageCompression.getDataUrlFromFile(compressedFile);
    } catch (error) {
      console.error("Error al comprimir la imagen:", error);
      return undefined;
    }
  };

  const sendMessage = (idMedia: string | undefined) => {
    if (!props.idChat) return;

    const messageObj: MessageParams = {
      id: props.idChat,
      idUserSender: Auth._User?.user?.id!,
      idMediaDataProfileSender: Auth._User?.user?.perfilData?.idMediaDataProfile,
      idUserReceiver: props.userData?.user?.id,
      idMediaDataProfileReceiver: props.userData?.user?.perfilData?.idMediaDataProfile,
      messageValue: message,
      usernameReceiver: props.userData?.user?.username,
      verifyUserReceiver: 1,
      usernameSender: Auth._User?.user?.username!,
      verifyUserSender: 1,
      nameReceiver: props.userData?.user?.name,
      nameSender: Auth._User?.user?.name,
      nameGroup: "",
      type: image ? MessageType.IMAGE : MessageType.TEXT,
      idMedia: idMedia,
      isDirect: true,
      createDate: `${Data._Today}`,
    };

    const messageSaveObj: MessageSaveParams = {
      idChat: props.idChat,
      idUser: Auth._User?.user?.id!,
      type: image ? MessageType.IMAGE : MessageType.TEXT,
      idMedia:idMedia,
      messageValue: message,
    };

    saveMessage(messageSaveObj);
    props.sendMessage(messageObj);
    resetForm();
  };

  const resetForm = () => {
    setMessage("");
    setImage(null);
    setPreview(null);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      setImage(img);
      setPreview(URL.createObjectURL(img));
    }
  };

  return (
    <div>
      {preview && (
        <div style={{ display: "flex" }}>
          <div
            style={{
              marginTop: "-310px",
              marginLeft: "15px",
              cursor: "pointer",
            }}
            onClick={resetForm}
          >
            <FontAwesomeIcon icon={faX} />
          </div>
          <div
            style={{
              width: "300px",
              height: "300px",
              marginTop: "-300px",
              position: "absolute",
              marginLeft: "30px",
            }}
          >
            <img
              style={{
                height: "100%",
                objectFit: "cover",
                objectPosition: "center center",
              }}
              src={preview}
              alt="Selected"
            />
          </div>
        </div>
      )}
      <div style={{ display: "flex" }}>
        <input
          type="text"
          className="messageInput"
          placeholder="message..."
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          disabled={!props.idChat}
          onKeyPress={handleKeyPress}
          autoComplete="off"
        />
        {!message && !image ? (
          <div
            style={{
              marginLeft: "20px",
              cursor: "pointer",
            }}
            onClick={() => imageInputRef.current?.click()}
          >
            <FontAwesomeIcon
              style={{ marginTop: "6px" }}
              className="iconInput"
              icon={faCamera}
            />
          </div>
        ) : (
          <button
            className="btn btn-primary buttonsendmessage"
            onClick={handleSave}
            disabled={!message && !image}
          >
            Send
          </button>
        )}
        <input
          type="file"
          accept="image/*"
          ref={imageInputRef}
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
      </div>
    </div>
  );
};

export default SendMessageForm;
