import React, { useEffect, useState } from 'react'
import { PostModel } from '../Models/Post/PostModel'
import { UserPerfilModel } from '../Models/User/UserPerfilModel'
import Auth from '../Services/Auth/AuthService'
import Post from '../Services/Post/PostService'
import User from '../Services/User/UserService'

export const useExplorerHook = () => {
    const [tendencias, setTendencias] = useState<PostModel[]>([])
    const [mosaicos, setMosaicos] = useState<PostModel[]>([])
    const [users, setUsers] = useState<any[]>([])

    const [tendenciasCharger, setTendenciasCharger] = useState<boolean>(true)
    const [mosaicosCharger, setMosaicosCharger] = useState<boolean>(true)

    useEffect(() => {
        getTendencias(Auth._User?.user?.id!)
        getMosaico(Auth._User?.user?.id!)
        getUserExplorer(1)
    }, [])


    const getUserExplorer = (section: number) => {
        User.GetExplorerUsers(section)
            .then((e: any) => {
                setUsers(e.data)
            })
            .catch((err: any) => {
                console.error(err)
            })
            .finally(() => {

            })
    }


    const getTendencias = (id: string) => {
        Post.GetTendenciasPost(id)
            .then((e: any) => {
                setTendencias(e.data)
            })
            .catch((err: any) => {
                console.error(err)
            })
            .finally(() => {
                setTendenciasCharger(false)
            })
    }

    const getMosaico = (id: string) => {
        Post.GetMosaicoPost(id, 1)
            .then((e: any) => {
                setMosaicos(e.data)
            })
            .catch((err: any) => {
                console.error(err)
            })
            .finally(() => {
                setMosaicosCharger(false)
            })
    }
    return {
        tendenciasCharger,
        tendencias,
        mosaicosCharger,
        mosaicos,
        users
    }
}
