import { faChevronLeft, faChevronRight, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../../../../context/AppContext'
import { ImageModel } from '../../../../Models/Post/ImageModel'
import { PostModel } from '../../../../Models/Post/PostModel'
import Auth from '../../../../Services/Auth/AuthService'
import Publications from '../../Publications'
import './PerfilPublication.css'
import PerfilPublicationImage from './PerfilPublicationImage'

interface IPerfilPublication {
    post: PostModel[]
    setPost: any
}

const PerfilPublication = ({ post, setPost }: IPerfilPublication) => {
    const [PostOnView, setPostOnView] = useState<boolean>(false)
    const [indexPost, setindexPost] = useState<number>(0);
    
    const Context = useContext(AppContext);

    if (!Context) {
        // Manejar el caso cuando el contexto es nulo
        return null;
    }
    const { OnLoadPost, } = Context;


    const setPostDelete = (a: any) => {
        setPost(post.filter((p: PostModel) => p.id != post[indexPost].id))
    }


    const scrollToElement = (index: number) => {
        setindexPost(index)
        setPostOnView(true)
    };

    return (
        <>
            <div className='container container-post'>
                {post.map((p: PostModel, index: number) => (
                    <PerfilPublicationImage index={index} scrollToElement={scrollToElement} publication={p} />
                ))}
            </div>

            {PostOnView && post.length > 0 && post[indexPost] !== undefined &&
                <div className="P_modal_box">
                    <div className="P_modal_container">
                        <div className='div_view_container'>
                            <div className='chevron_box'>
                                {indexPost !== 0 && (
                                    <div onClick={() => {
                                        if (indexPost > 0) {
                                            setindexPost(indexPost - 1)
                                        }
                                    }}>
                                        <FontAwesomeIcon className='arrow_left_box' icon={faChevronLeft} />
                                    </div>)

                                }
                            </div>
                            <div className="ViewModal_P">
                                <Publications
                                    iduser={Auth._User?.user?.id}
                                    Post={[post[indexPost]]}
                                    OnLoadPost={OnLoadPost}
                                    setPost={setPostDelete}
                                    charging={false}
                                />
                            </div>
                            <div className='chevron_box'>
                                {post.length > indexPost + 1 && (
                                    <div onClick={() => { setindexPost(indexPost + 1) }}><FontAwesomeIcon className='arrow_left_box' icon={faChevronRight} /></div>
                                )}
                            </div>
                        </div>
                    </div>


                    <div>
                        <div onClick={() => { setPostOnView(false) }}><FontAwesomeIcon className='closeIcon' icon={faX} /></div>
                    </div>
                </div >
            }
        </>
    )
}

export default PerfilPublication