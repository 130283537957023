import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { MessageParams } from '../../Models/Message/MessageParams';
import './InboxBox.css'
import Chat from './InboxComponent/ChatComponents/Chat'
import InboxListUsersBox from './InboxComponent/InboxListUsersComponents/InboxListUsersBox'

interface IInboxBox {
    usersConnecting: any;
    messages: any
    LastMessage: MessageParams
    sendMessage: Function
    setMessages: Function
}

const InboxBox = (props: IInboxBox) => {
    let { username } = useParams();
    const [chatPlease, setchatPlease] = useState<boolean>(true)


    useEffect(() => {
      if(username){
        setchatPlease(false)
      }
    }, [username])
    
    return (
        <>
            <div className='InboxBox col-md-10'>
                <InboxListUsersBox usersConnecting={props.usersConnecting} NewcurrencyConversation={props.LastMessage} />
                <div className='v-line'></div>
                <Chat
                    usersConnecting={props.usersConnecting}
                    messages={props.messages}
                    sendMessage={props.sendMessage}
                    setMessages={props.setMessages}
                    setchatPlease={setchatPlease}
                />
            </div>

            <div className='InboxBox-max-widt col-md-10'>
                {chatPlease
                    ? <InboxListUsersBox usersConnecting={props.usersConnecting} NewcurrencyConversation={props.LastMessage} />
                    : <Chat
                        usersConnecting={props.usersConnecting}
                        messages={props.messages}
                        sendMessage={props.sendMessage}
                        setMessages={props.setMessages}
                        setchatPlease={setchatPlease}
                    />
                }
            </div>
        </>
    )
}

export default InboxBox