import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import SignForm from "../Modules/SignForm/SignForm";
import Auth from "../Services/Auth/AuthService";

const SignUp = () => {
  return Auth._Authenticated ? <Navigate to="/"/> :  <SignForm />;
};

export default SignUp;
