import Http from '../Http/HttpClient';
import { ILikeService } from './../../Interface/Like/ILikeService';

export class LikeService implements ILikeService{
    async setHistoryLike(iduser: string, idhistory: string): Promise<any> {
        const likeparams = {
            "idhistory": idhistory,
            "iduser": iduser
        }
        let result = await new Promise<any>((resolve, reject) => {
            Http.post('/api/Like/SetHistoryLike', likeparams)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }
    async RemoveHistoryLike(iduser: string, idhistory: string): Promise<any> {
        const likeparams = {
            "idhistory": idhistory,
            "iduser": iduser
        }
            let result = await new Promise<any>((resolve, reject) => {
                Http.delete('/api/Like/DeleteHistoryLike',{data: likeparams} )
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
            return result;
    }
    async setLikeComent(idUser: string, idComent: string): Promise<any> {
        const likeparams = {
            "idcoment": idComent,
            "iduser": idUser
        }
        let result = await new Promise<any>((resolve, reject) => {
            Http.post('/api/Like/setComentLike', likeparams)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }
    async RemoveLikeComent(idUser: string, idComent: string): Promise<any> {
        const likeparams = {
            "idcoment": idComent,
            "iduser": idUser
        }
            let result = await new Promise<any>((resolve, reject) => {
                Http.delete('/api/Like/DeleteComentLike',{data: likeparams} )
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
            return result;
    }

    GetLikeComentUsers(idcoment: string): Promise<any> {
        throw new Error('Method not implemented.');
    }

    async Setlike(idUser: string, idPost: string): Promise<any> {
        const likeparams = {
            "idpost": idPost,
            "iduser": idUser
        }
        let result = await new Promise<any>((resolve, reject) => {
            Http.post('/api/Like', likeparams)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return result;
    }

    async RemoveLike(idUser: string, idPost: string): Promise<any> {
        const likeparams = {
            "idpost": idPost,
            "iduser": idUser
        }
            let result = await new Promise<any>((resolve, reject) => {
                Http.delete('/api/Like',{data: likeparams} )
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
            return result;
    }

    GetLikeUsers(idPost: string): Promise<any> {
        throw new Error('Method not implemented.');
    }

}

const Like = new LikeService();
export default Like;