import React, { useContext, useEffect, useState } from "react";
import "./Publication.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  faComment,
  faEllipsisVertical,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import Coments from "../Coments/Coments";
import { ComentModel } from "../../../Models/Coment/ComentModel";
import { PostModel } from "../../../Models/Post/PostModel";
import Like from "../../../Services/Like/LikeService";
import Auth from "../../../Services/Auth/AuthService";
import Coment from "../../../Services/Coments/ComentService";
import { ComentParams } from "../../../Models/Coment/ComentParams";
import Data from "../../../Services/Data/Data";
import { FillPostParams } from "../../../Models/Post/FillPostParams";
import { AppContext } from "../../../context/AppContext";
import { ImageModel } from "../../../Models/Post/ImageModel";
import Post from "../../../Services/Post/PostService";
import MediaData from "../../../Services/MediaData/MediaDataService";

interface IPublication {
  publication: PostModel
  setViewOP: Function
  setOPData: Function
  setFillPost: Function
}

function Publication({ publication, setViewOP, setOPData, setFillPost }: IPublication) {
  const navigate = useNavigate()
  const [allComents, setAllComents] = useState<ComentModel[]>([])
  const [inputComent, setInputComent] = useState<boolean>(false)
  const [seeComents, setSeeComents] = useState<boolean>(false)
  const [comentText, setComentText] = useState<string>("")
  const [spinnerComent, setSpinnerComent] = useState<boolean>(false)
  const [classlike, setClasslike] = useState<boolean>(false)
  const [image, setImage] = useState<ImageModel>()

  useEffect(() => {
    if (classlike) {
      const timeout = setTimeout(() => {
        setClasslike(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [classlike]);

  const getComents = () => {
    Coment.GetAll(publication.id!, Auth._User?.user?.id!, 1)
      .then((e: any) => {
        setAllComents(e.data);
      })
      .catch((err: any) => {
        console.log(err);
      })
  }

  const ActionLike = (idUser: string, idPost: string) => {
    publication.liked
      ? Like.RemoveLike(idUser, idPost)
        .then((e: any) => {
        })
        .catch((err: any) => {
          console.log(err);
        })
      : Like.Setlike(idUser, idPost)
        .then((e: any) => {
        })
        .catch((err: any) => {
          console.log(err);
        })
  }

  const handleKeyPress = (e: any) => {
    if (e.key == 'Enter') {
      SaveComent();
    }
  }

  const SaveComent = () => {
    if (comentText) {
      setSpinnerComent(true)
      const C: ComentParams = {
        idcoment: "",
        idpost: publication.id,
        iduser: Auth._User?.user?.id,
        coment: comentText,
        createDate_Js: Data._Today.toISOString()
      }
      Coment.Save(C)
        .then((e: any) => {
          // e.data.profilePhoto = Auth._User?.User?.PerfilData?.ProfilePhoto
          setAllComents((allComents: any) => [...allComents, e.data])
          FillPost(publication.liked!, publication.likes!, publication.comments! + 1)
          setComentText("")
          setInputComent(false)
          setSpinnerComent(false)

        })
        .catch((err: any) => {
          console.log(err);
        })
    }
  }

  const FillPost = (liked: boolean, AOLikes: number, AOComents: number) => {
    const FillParams: FillPostParams = {
      id: publication.id,
      liked: liked,
      amountOfLikes: AOLikes,
      amountOfComents: AOComents
    }
    setFillPost(FillParams)
  }


  const handleComentChange = (modifiedComent: ComentModel) => {
    const updatedComents = allComents.map((coment) => {
      if (coment.id === modifiedComent.id) {
        return modifiedComent;
      }
      return coment;
    });
    setAllComents(updatedComents);
  };

  const deleteComment = (indexToDelete: number) => {
    const updatedComments = allComents.filter((_, index) => index !== indexToDelete);
    setAllComents(updatedComments);
    FillPost(publication.liked!, publication.likes!, publication.comments! - 1)
  };

  function Time(fecha: string): string {
    const fechaIngresada = new Date(fecha);
    const fechaActual = new Date();

    const esMismoDia =
      fechaIngresada.getDate() === fechaActual.getDate() &&
      fechaIngresada.getMonth() === fechaActual.getMonth() &&
      fechaIngresada.getFullYear() === fechaActual.getFullYear();

    const diferenciaEnMilisegundos = fechaActual.getTime() - fechaIngresada.getTime();
    const segundos = Math.floor(diferenciaEnMilisegundos / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);

    if (esMismoDia) {
      return 'Hoy'
    }
    else {
      return `Hace ${dias} días`;
    }
  }

  useEffect(() => {
    handleSrc(publication.idMediaData!)
  }, [publication.id])

  const handleSrc = (id: string) => {
    const image = searchImage(id)
    if (image.length > 0) {
      setImage(image[0])
    }
    else {
      MediaData.GetById(id)
        .then((e: any) => {
          setImage(e.data)
          handleImageBank(e.data)
        })
        .catch((err: any) => {
          console.error(err)
        })

    }
  }

  const Context = useContext(AppContext);
  if (!Context) {
    return null;
  }
  const { searchImage, handleImageBank } = Context;

  return (
    <>
      {publication.id && (
        <div key={publication.id} className="container list-item">
          <div className="row">
            <div className="col-md-6 publication">
              {image
                ? <div className="ImageBox">
                  <img src={image?.value} onDoubleClick={() => {
                    setClasslike(true)
                    if (!publication.liked) {
                      ActionLike(Auth._User?.user?.id!, publication.id!)
                      FillPost(!publication.liked, publication.likes! + 1, publication.comments!)
                    }
                  }} />
                  <FontAwesomeIcon style={{ color: 'white' }}
                    className={classlike ? 'like' : ''}
                    id="icon_Heart_like"
                    icon={faHeart}
                  />
                </div>
                : <div className="ImageBoxCharger color-changing" />
              }
              <div className="InteratorPost">
                <div className="LikeComentIcon">
                  <div className="LikeIcon" onClick={() => {
                    ActionLike(Auth._User?.user?.id!, publication.id!)
                    publication.liked
                      ? FillPost(!publication.liked, publication.likes! - 1, publication.comments!)
                      : FillPost(!publication.liked, publication.likes! + 1, publication.comments!)
                  }}>
                    {publication.liked && (
                      <FontAwesomeIcon style={{ color: 'red' }}
                        className="icon"
                        icon={faHeart}
                      />
                    )}
                    {!publication.liked && (
                      <FontAwesomeIcon
                        className="icon"
                        icon={faHeart}
                      />
                    )}
                  </div>
                  <div className="ComentnIcon" onClick={() => setInputComent(!inputComent)}>
                    <FontAwesomeIcon
                      className="icon"
                      icon={faComment}
                    />
                  </div>
                </div>
                {publication.idUser == Auth._User?.user?.id && (
                  <div className="settingIcon"
                    onClick={() => {
                      if (setViewOP) {
                        setViewOP(true)
                        setOPData(publication)
                      }
                    }}>
                    <FontAwesomeIcon className="icon" icon={faEllipsisVertical} />
                  </div>
                )}
              </div>
              <div className="statePublication">
                {publication.likes !== 0 && (
                  <span>{publication.likes === 1 ? `${publication.likes} Like` : `${publication.likes} Likes`}</span>
                )}
              </div>
              <div className="statePublication">
                <span>{publication.description}</span>
              </div>
              <div className="statePublication">
                <strong
                  className="userPublication"
                  onClick={() => navigate(`/${publication.username}`, { replace: true })}
                >
                  {publication.username}
                </strong>
              </div>
              {publication.comments !== 0 && (
                <div className="statePublication">
                  <span className="NumComent" onClick={() => { setSeeComents(!seeComents) }}>Ver {publication.likes === 1 ? `comentario` : `${publication.comments} comentarios`}</span>
                </div>
              )}
              {inputComent && (
                <div className="comentInputBox col-md-12 d-flex">
                  <div className="InputComent col-md-10">
                    <input
                      type="text"
                      placeholder="Agrega un comentario..."
                      value={comentText}
                      onChange={(e) => setComentText(e.target.value)}
                      onKeyPress={handleKeyPress}
                      autoComplete="off"
                      disabled={spinnerComent}
                    />
                  </div>
                  {!spinnerComent && (
                    <div className="col-md-2 text-center texto-publi" onClick={SaveComent}>
                      <b style={{ color: '#1877f2' }}>Publicar</b>
                    </div>
                  )}
                  {spinnerComent && (
                    <div className="col-md-2 text-center texto-publi">
                      <div className="spinner-border text-primary coment-spinner" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div>
                {seeComents && (
                  allComents.length > 0
                    ? allComents.map((c: ComentModel, index: number) => (
                      <Coments coment={c} key={index} handleComentChange={handleComentChange} onDelete={() => deleteComment(index)} />
                    ))
                    : (
                      <div className="col-md-12 text-center texto-publi">
                        {getComents()}
                        <div className="spinner-border text-primary coment-spinner" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )
                )}
              </div>
              {/* <span style={{ fontSize: "10px" }}>{Time(publication.date!)}</span> */}
            </div>
          </div>
          <br />
          <br />
        </div>
      )}
    </>
  );
}

export default Publication;
