import React, { useContext, useEffect } from "react"
import Nav from "../Modules/Nav/Nav"
import Space from "../Modules/Nav/SpaceBehindNav"
import HistoryComponet from "../Modules/Historys/HistoryComponet"
import Footer from "../Modules/Footer/Footer"
import SearchBox from "../Modules/SearchBox/SearchBox"
import { HistoryListItemModel } from "../Models/History/HistoryListItemModel"
import { PostModel } from "../Models/Post/PostModel"
import { PostParams } from "../Models/Post/PostParams"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Interactor from "../Modules/Interactor/Interactor"
import { AppContext } from "../context/AppContext"

interface IExplorer {
  buttonVisible: boolean
  setButtonsVisible: Function
  themes: string
  setThemes: Function
  searchVisible: boolean
  setSearchVisible: Function
  usersSearch: []
  setUsersSearch: Function
  searchInput: boolean
  setSearchInput: Function
  closeConnection: Function
  setPhotos: Function
  Post: PostModel[]
  setOnLoadPhoto: Function
  OnLoadPost: PostParams | undefined
  setFooterViewer: Function
  footerViewer: boolean
  setPost: Function
  historyList: HistoryListItemModel[]
  setHistorys: Function
  myhistorys: HistoryListItemModel[]
  addMyHistory: Function
  setMyHistorys: Function
}

const Explorer = () => {

  useEffect(() => {
    document.title = `Explorador • ${process.env.REACT_APP_TITTLE_BASE}`;
    return () => {
      document.title = `${process.env.REACT_APP_TITTLE_BASE}`;
    };
  }, []);

  const Context = useContext(AppContext);
  if (!Context) {
    return null;
  }
  const { searchVisible } = Context;

  return (
    <>
      {!searchVisible && (
        <Interactor />
      )}
    </>
  );
};

export default Explorer;
