import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import './PostOptions.css'

const PerfilOptions = ({ setViewPPO, setArchivedView }: any) => {
    const navigate = useNavigate();
    return (
        <div className="OPmodal_box">
            <div className="OPmodal_container">
                <div className="OPmodal_button">
                    <div
                        onClick={() => {
                            setViewPPO(false)
                        }}
                        className='closeBox'>
                        <FontAwesomeIcon icon={faX} />
                    </div>

                    <div className="OptionsList">
                        <div className='OPoption' onClick={() => navigate(`/settings`, { replace: true })}>
                            <b>Configuracion</b>
                        </div>
                        <div className='OPoption' onClick={() => {
                            setArchivedView(true)
                            setViewPPO(false)
                        }}
                        >
                            <b>Archivado</b>
                        </div>
                        <div className='OPoption'>
                            <b>Guardado</b>
                        </div>
                        <div className='OPoption' onClick={() => setViewPPO(false)}>
                            <b>Cancelar</b>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default PerfilOptions