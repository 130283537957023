import { IMediaDataService } from "../../Interface/MediaData/IMediaDataService";
import { MediaDataModel } from "../../Models/MediaData/MediaDataModel";
import Http from "../Http/HttpClient";

export class MediaDataService implements IMediaDataService {
  async Create(model: MediaDataModel): Promise<any> {
    let result = await new Promise<any>((resolve, reject) => {
      Http.post(`/api/MediaData`, model)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async CreateCover(idUser: string, model: MediaDataModel): Promise<any> {
    let result = await new Promise<any>((resolve, reject) => {
      Http.post(`/api/MediaData/CreateCover?idUser=${idUser}`, model)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async CreateProfile(idUser: string, model: MediaDataModel): Promise<any> {
    let result = await new Promise<any>((resolve, reject) => {
      Http.post(`/api/MediaData/CreateProfile?idUser=${idUser}`, model)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return result;
  }
  async GetById(id: string): Promise<any> {
    if (id) {
      let result = await new Promise<any>((resolve, reject) => {
        Http.get(`/api/MediaData?id=${id}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
      return result;
    }
  }
}

const MediaData = new MediaDataService();
export default MediaData;
