import { IFollowService } from "../../Interface/Follow/IFollowService";
import Auth from "../Auth/AuthService";
import Http from "../Http/HttpClient";

export class FollowService implements IFollowService {
    async GetFollowerList(id: string, section: number): Promise<any> {
        let list = await new Promise<any>((resolve, reject) => {
            Http.get(`api/Follow/GetFollower?Id=${id}&IdUserlog=${Auth._ID}&section=${section}`)
                .then((res) => {
                    resolve(res);
                    
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return list;
    }

    async FollowUnFollow(idUserlog: string | undefined, idUser: string | undefined, IsFollow: boolean): Promise<any> {
        let follow = await new Promise<any>((resolve, reject) => {
            Http.post(`/api/Follow?myId=${idUserlog}&otherUserId=${idUser}&status=${IsFollow}`)
                .then((res) => {
                    resolve(res);
                    
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return follow;
    }
    async GetFollowingList(id: string, section: number): Promise<any> {
        let list = await new Promise<any>((resolve, reject) => {
            Http.get(`/api/Follow/GetFollowing?Id=${id}&IdUserlog=${Auth._ID}&section=${section}`)
                .then((res) => {
                    resolve(res);
                    
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return list;
    }

}
const Follow = new FollowService();
export default Follow;